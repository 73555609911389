import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { AuditListTable } from '../_models';
import { Data } from '../_providers';
import { BehaviorSubject, Observable } from 'rxjs';


export class AuditListDatasource implements DataSource<AuditListTable> {

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 100;
  private readonly tableData: any;
  private readonly currentRegion: any;
  private tableDisplayData = new BehaviorSubject<AuditListTable[]>([]);
  private fakeScoreArray: boolean[] = [];
  private calcYearToDateFlag: boolean[] = [];
  private submissionKeys: {Percentage: string, PercentageColour: string}[] = [];
  public auditTableDisplayColumns: string[] = [];
  public auditTableDisplayColumnsLabels: string[] = [];
  public auditTableDynamicColumns: string[] = [];
  

  

  public constructor(private data: Data) {
    // console.log('AuditListDatasource: ', data)
    this.currentRegion = data.regionList.find(x => x.id === data.currentRegionID);
    this.tableData = data.auditList;
    for (let i = 0; i < this.tableData.length; i++) {
      this.fakeScoreArray.push(false);
    }

    for (let i = 0; i < this.tableData.length; i++) {
      this.calcYearToDateFlag.push(false);
    }
  }

  static scoreColour(score: number, status: number) {
    if (status < 2) {
      return '#5a5a5a';
    } else if (score < 90) {
      return '#FF2600';
    } else if (score < 95) {
      return '#FF9300';
    } else {
      return '#017100';
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<AuditListTable[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  calcSubmision(submission: any, ytdPrevent, fake, weight) {
    let pointsAwardedIncremented = 0;
    let pointsAllocatedIncremented = 0;
    let lciIncrement = 0;
    let displayReal = false; // editable
    let percent = '-';
    let colour = '';

    if (submission.na) {
      percent = 'N/A';
      colour = AuditListDatasource.scoreColour(0, 0);
      displayReal = false;
    } else if (submission.status > 1 && !ytdPrevent) {
      const percentNum = Math.round((submission.dash_points_awarded / submission.dash_points_allocated) * 100) - submission.lci_count;
      percent = percentNum + '%';
      colour = AuditListDatasource.scoreColour(percentNum, submission.status);
      displayReal = true;
    } else if (fake) {
      percent = '100%';
      colour = AuditListDatasource.scoreColour(0, 0);
      displayReal = false;
    } else {
      percent = '-';
      colour = AuditListDatasource.scoreColour(0, 0);
      displayReal = false;
    }

    if (!ytdPrevent && !submission.na) {
      if (displayReal) {
        lciIncrement += submission.lci_count;
        pointsAwardedIncremented += submission.dash_points_awarded * weight; // 50%
        pointsAllocatedIncremented += submission.dash_points_allocated * weight; // 50%

      } else if (fake) {
        pointsAwardedIncremented += submission.dash_points_allocated * weight; // 50%
        pointsAllocatedIncremented += submission.dash_points_allocated * weight; // 50%
      }
    }
    return {
      'pai': pointsAwardedIncremented,
      'pali': pointsAllocatedIncremented,
      'li': lciIncrement,
      'dr': displayReal,
      'p': percent,
      'c': colour
    };
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;

   
    const tempData: AuditListTable[] = [];
    this.auditTableDisplayColumns = [];
    this.auditTableDisplayColumnsLabels = [];
    this.auditTableDynamicColumns = [];
    for (let i = pageIndex * pageSize; i < this.tableData.length && i < (pageIndex + 1) * pageSize; i++) {
      const tempObj: any = {};
      tempObj.id = this.tableData[i].id;
      tempObj.type = 'audit';
      tempObj.isR = this.tableData[i].isR;
      tempObj.position = i;
      tempObj.name = this.tableData[i].name;


      let d = new Date();
      let m = d.getMonth(); // remember  0-11 not 1-12
      // console.log(m);
      let y = d.getFullYear();

      tempObj.legalYTD = 0;
      let totalAwardedIncremented = 0;
      let totalAllocatedIncremented = 0;
      // @formatter:off
      /* tslint:disable:max-line-length */
      let erm;

      // Set the Mat Table display headers
      if (i === 0) {
        this.auditTableDisplayColumns = ['name'];
        this.auditTableDisplayColumnsLabels = [];
      }
      this.tableData[i].submissions.forEach(submission => {
        erm = this.calcSubmision(submission, m < 1 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], submission.submission_template ? Number(submission.submission_template.weight) : 0.5);
        // tempObj.submissionJanPercentage = erm.p;
        // tempObj.submissionJanPercentageColour = erm.c;
         // tempObj.submissionJanEditable = erm.dr;

        tempObj['submission'+ submission.name +'Percentage'] = erm.p;
        tempObj['submission'+ submission.name +'PercentageColour'] = erm.c;
        tempObj['submission'+ submission.name +'PercentageEditable'] = erm.dr;
        
        totalAllocatedIncremented += erm.pali;
        totalAwardedIncremented += erm.pai;
        tempObj.legalYTD += erm.li;

        // Generate the Mat Table display headers
        if (i === 0) {
          this.auditTableDisplayColumns.push('submission'+ submission.name +'Percentage');
          this.auditTableDisplayColumnsLabels.push(submission.name);
          this.auditTableDynamicColumns.push('submission'+ submission.name +'Percentage');
          // build a key list 
          this.submissionKeys.push({Percentage: 'submission'+ submission.name +'Percentage', PercentageColour: 'submission'+ submission.name +'PercentageColour' })
        }
        

        
      });
      // Generate the Mat Table display headers additons
      if (i === 0) {
        this.auditTableDisplayColumns.push('rankYTD','legalYTD', 'scoreAdjust','scoreTYD');
        this.auditTableDisplayColumnsLabels.push('YTD Rank','YTD LNCs', 'Score Adjust','YTD');
      }
      // let erm = this.calcSubmision(this.tableData[i].submission_jan, m < 1 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.5);
      // tempObj.submissionJanPercentage = erm.p;
      // tempObj.submissionJanPercentageColour = erm.c;
      // tempObj.submissionJanEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;


      // erm = this.calcSubmision(this.tableData[i].submission_feb, m < 2 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.5);
      // tempObj.submissionFebPercentage = erm.p;
      // tempObj.submissionFebPercentageColour = erm.c;
      // tempObj.submissionFebEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_mar, m < 3 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.5);
      // tempObj.submissionMarPercentage = erm.p;
      // tempObj.submissionMarPercentageColour = erm.c;
      // tempObj.submissionMarEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;      

      // erm = this.calcSubmision(this.tableData[i].submission_q1, m < 3 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.5);
      // tempObj.submissionQ1Percentage = erm.p;
      // tempObj.submissionQ1PercentageColour = erm.c;
      // tempObj.submissionQ1Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_apr, m < 4 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.65);
      // tempObj.submissionAprPercentage = erm.p;
      // tempObj.submissionAprPercentageColour = erm.c;
      // tempObj.submissionAprEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // // console.log('submission_apr erm: ', erm)

      // erm = this.calcSubmision(this.tableData[i].submission_may, m < 5 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.65);
      // tempObj.submissionMayPercentage = erm.p;
      // tempObj.submissionMayPercentageColour = erm.c;
      // tempObj.submissionMayEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;


      // erm = this.calcSubmision(this.tableData[i].submission_jun, m < 6 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.65);
      // tempObj.submissionJunPercentage = erm.p;
      // tempObj.submissionJunPercentageColour = erm.c;
      // tempObj.submissionJunEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_q2, m < 6 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.65);
      // tempObj.submissionQ2Percentage = erm.p;
      // tempObj.submissionQ2PercentageColour = erm.c;
      // tempObj.submissionQ2Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_b1, m < 6 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.65);
      // tempObj.submissionB1Percentage = erm.p;
      // tempObj.submissionB1PercentageColour = erm.c;
      // tempObj.submissionB1Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_jul, m < 7 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.75);
      // tempObj.submissionJulPercentage = erm.p;
      // tempObj.submissionJulPercentageColour = erm.c;
      // tempObj.submissionJulEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;


      // erm = this.calcSubmision(this.tableData[i].submission_aug, m < 8 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.75);
      // // console.log("this.tableData[i].submission_aug", this.tableData[i].submission_aug)
      // tempObj.submissionAugPercentage = erm.p;
      // tempObj.submissionAugPercentageColour = erm.c;
      // tempObj.submissionAugEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;
      // // console.log('erm',erm);

      // erm = this.calcSubmision(this.tableData[i].submission_sep, m < 9 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.75);
      // tempObj.submissionSepNA = this.tableData[i].submission_sep.na;
      // tempObj.submissionSepPercentage = erm.p;
      // tempObj.submissionSepPercentageColour = erm.c;
      // tempObj.submissionSepEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_q3, m < 9 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 0.75);
      // tempObj.submissionQ3Percentage = erm.p;
      // tempObj.submissionQ3PercentageColour = erm.c;
      // tempObj.submissionQ3Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;


      // erm = this.calcSubmision(this.tableData[i].submission_oct, m < 10 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionOctPercentage = erm.p;
      // tempObj.submissionOctPercentageColour = erm.c;
      // tempObj.submissionOctEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_nov, m < 11 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionNovPercentage = erm.p;
      // tempObj.submissionNovPercentageColour = erm.c;
      // tempObj.submissionNovEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_dec, m < 12 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionDecPercentage = erm.p;
      // tempObj.submissionDecPercentageColour = erm.c;
      // tempObj.submissionDecEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_q4, m < 12 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionQ4Percentage = erm.p;
      // tempObj.submissionQ4PercentageColour = erm.c;
      // tempObj.submissionQ4Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_b2, m < 12 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionB2Percentage = erm.p;
      // tempObj.submissionB2PercentageColour = erm.c;
      // tempObj.submissionB2Editable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;

      // erm = this.calcSubmision(this.tableData[i].submission_annual, m < 12 && this.calcYearToDateFlag[i], this.fakeScoreArray[i], 1);
      // tempObj.submissionAnnualPercentage = erm.p;
      // tempObj.submissionAnnualPercentageColour = erm.c;
      // tempObj.submissionAnnualEditable = erm.dr;
      // totalAllocatedIncremented += erm.pali;
      // totalAwardedIncremented += erm.pai;
      // tempObj.legalYTD += erm.li;





      /* tslint:enable:max-line-length */
      // @formatter:on

      tempObj.scoreAdjust = tempObj.legalYTD * -1;

      tempObj.fakeScore = this.fakeScoreArray[i];

      if (totalAllocatedIncremented === 0) {
        totalAllocatedIncremented++;
      }
      tempObj.scoreTYD = parseFloat((((totalAwardedIncremented / totalAllocatedIncremented) * 100) - tempObj.legalYTD).toString())
        .toFixed(1);

      tempObj.scoreTYDColour = AuditListDatasource.scoreColour(tempObj.scoreTYD, 2);
      tempObj.rankYTD = this.tableData[i].ranking_group_ytd_rank;
      if (this.tableData[i].ytd_rank_equals_flag) {
        tempObj.rankYTD = '=' + tempObj.rankYTD;
      }


      tempData.push(tempObj);
    }

    // replaced with dynamic keys
    // const submissionKeys = [
    //   { Percentage: 'submissionJanPercentage', PercentageColour: 'submissionJanPercentageColour' },
    //   { Percentage: 'submissionFebPercentage', PercentageColour: 'submissionFebPercentageColour' },
    //   { Percentage: 'submissionMarPercentage', PercentageColour: 'submissionMarPercentageColour' },
    //   { Percentage: 'submissionQ1Percentage', PercentageColour: 'submissionQ1PercentageColour' },
    //   { Percentage: 'submissionAprPercentage', PercentageColour: 'submissionAprPercentageColour' },
    //   { Percentage: 'submissionMayPercentage', PercentageColour: 'submissionMayPercentageColour' },
    //   { Percentage: 'submissionJunPercentage', PercentageColour: 'submissionJunPercentageColour' },
    //   { Percentage: 'submissionQ2Percentage', PercentageColour: 'submissionQ2PercentageColour' },
    //   { Percentage: 'submissionB1Percentage', PercentageColour: 'submissionB1PercentageColour' },
    //   { Percentage: 'submissionJulPercentage', PercentageColour: 'submissionJulPercentageColour' },
    //   { Percentage: 'submissionAugPercentage', PercentageColour: 'submissionAugPercentageColour' },
    //   { Percentage: 'submissionSepPercentage', PercentageColour: 'submissionSepPercentageColour' },
    //   { Percentage: 'submissionQ3Percentage', PercentageColour: 'submissionQ3PercentageColour' },
    //   { Percentage: 'submissionOctPercentage', PercentageColour: 'submissionOctPercentageColour' },
    //   { Percentage: 'submissionNovPercentage', PercentageColour: 'submissionNovPercentageColour' },
    //   { Percentage: 'submissionDecPercentage', PercentageColour: 'submissionDecPercentageColour' },
    //   { Percentage: 'submissionQ4Percentage', PercentageColour: 'submissionQ4PercentageColour' },
    //   { Percentage: 'submissionB2Percentage', PercentageColour: 'submissionB2PercentageColour' },
    //   { Percentage: 'submissionAnnualPercentage', PercentageColour: 'submissionAnnualPercentageColour' },
    // ];

    // Region row calculations
    const obj: any = {
      name: (this.currentRegion || { name: '-' }).name,
      type: 'region',
      rankYTD: '-',
      legalYTD: 0,
    };
    for (let tempObj of (tempData as any[])) {

      this.submissionKeys.forEach(key => {
        const percentage = parseFloat(tempObj[key.Percentage]);
        if (!Array.isArray(obj[key.Percentage])) { obj[key.Percentage] = []; }
        if (!isNaN(percentage)) { obj[key.Percentage].push(percentage); }
      })

      const scoreAdjust = parseFloat(tempObj.scoreAdjust);
      if (!Array.isArray(obj.scoreAdjust)) { obj.scoreAdjust = []; }
      if (!isNaN(scoreAdjust)) { obj.scoreAdjust.push(scoreAdjust); }

      const scoreTYD = parseFloat(tempObj.scoreTYD);
      if (!Array.isArray(obj.scoreTYD)) { obj.scoreTYD = []; }
      if (!isNaN(scoreTYD)) { obj.scoreTYD.push(scoreTYD); }

      const legalYTD = parseFloat(tempObj.legalYTD);
      if (!isNaN(legalYTD)) { obj.legalYTD += legalYTD; }

      // const rankYTD = parseFloat(tempObj.rankYTD);
      // if (!isNaN(rankYTD)) { obj.rankYTD = rankYTD; }

    }

    this.submissionKeys.forEach(key => {
      if (Array.isArray(obj[key.Percentage]) && obj[key.Percentage].length > 0) {
        const total = obj[key.Percentage].reduce((item, total) => (total + item), 0);
        const percentage = Math.round((total / (tempData.length * 100)) * 100);

        const colour = AuditListDatasource.scoreColour(percentage, 0);
        obj[key.PercentageColour] = colour;

        obj[key.Percentage] = percentage + '%';
      } else {
        obj[key.Percentage] = '-';
      }
    })

    if (Array.isArray(obj.scoreAdjust) && obj.scoreAdjust.length > 0) {
      obj.scoreAdjust = obj.scoreAdjust.reduce((item, total) => (total + item), 0);
      obj.scoreAdjust = Math.round((obj.scoreAdjust / (tempData.length * 100)) * 100);
    } else {
      obj.scoreAdjust = '-';
    }

    if (Array.isArray(obj.scoreTYD) && obj.scoreTYD.length > 0) {
      obj.scoreTYD = obj.scoreTYD.reduce((item, total) => (total + item), 0);
      const percentage = (Math.round(((obj.scoreTYD / (tempData.length * 100)) * 100) * 10) / 10);
      const colour = AuditListDatasource.scoreColour(percentage, 0);
      obj.scoreTYDColour = colour;
      obj.scoreTYD = Math.round(percentage * 100) / 100;
    } else {
      obj.scoreTYD = '-';
    }

    obj.isR = this.tableData.some(x => x.isR);

    tempData.unshift(obj)

    this.tableDisplayData.next(tempData);
  }


  rowChangePercentages(rowIndex: number, value: boolean) {
    this.fakeScoreArray[rowIndex] = value;
    this.loadData();
  }

  regionChangePercentages(value: boolean) {
    for (let i = 0; i < this.fakeScoreArray.length; i++) {
      this.fakeScoreArray[i] = value;
    }
    this.loadData();
  }

  regionChangeYearToDate(value: boolean) {
    for (let i = 0; i < this.calcYearToDateFlag.length; i++) {
      this.calcYearToDateFlag[i] = value;
    }
    this.loadData();
  }

  rowChangeYearToDate(rowIndex: number, value: boolean) {
    this.calcYearToDateFlag[rowIndex] = value;
    this.loadData();
  }
}
