import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType3} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type3DataSource implements DataSource<TableType3> {
  // inputJson =[
  // {
  // depot:'Depot 1,
  // diskFound: 'Yes',
  // driver:'Wayne Emlyn Nightingale',
  // dateUsed:'14/8/2017',
  // diskComp:'Ok',
  // licenceOkay:'Fail',
  // continuationReSigned: 'Ok',
  // photo: 'Fail',
  // chartsFoundCardDwnld:'N/A',
  // dtcoCardCopy:'N/A',
  // driverCpcCardCopy: 'Fail'',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType3[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation

    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 10) {
      for (let i = this.tableData.table_data.length; i < 10; i++) {
        const tempObj: any = {};
        tempObj.depot = '';
        tempObj.diskFound = '';
        tempObj.driver = '';
        tempObj.dateUsed = '';
        tempObj.diskComp = '';
        tempObj.licenceOkay = '';
        tempObj.continuationReSigned = '';
        tempObj.photo = '';
        tempObj.chartsFoundCardDwnld = '';
        tempObj.dtcoCardCopy = '';
        tempObj.driverCpcCardCopy = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType3[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType3[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.depot = this.tableData.table_data[i].depot;
      tempObj.diskFound = this.tableData.table_data[i].diskFound;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.dateUsed = new Date(this.tableData.table_data[i].dateUsed);
      tempObj.diskComp = this.tableData.table_data[i].diskComp;
      tempObj.licenceOkay = this.tableData.table_data[i].licenceOkay;
      tempObj.continuationReSigned = this.tableData.table_data[i].continuationReSigned;
      tempObj.photo = this.tableData.table_data[i].photo;
      tempObj.chartsFoundCardDwnld = this.tableData.table_data[i].chartsFoundCardDwnld;
      tempObj.dtcoCardCopy = this.tableData.table_data[i].dtcoCardCopy;
      tempObj.driverCpcCardCopy = this.tableData.table_data[i].driverCpcCardCopy;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDiskFound(index: number, diskFound: string) {
    this.tableData.table_data[index].diskFound = diskFound;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDateUsed(index: number, newDate: Date) {
    this.tableData.table_data[index].dateUsed = newDate.toISOString();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDiskComp(index: number, diskComp: string) {
    this.tableData.table_data[index].diskComp = diskComp;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateLicenceOkay(index: number, licenceOkay: string) {
    this.tableData.table_data[index].licenceOkay = licenceOkay;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateContinuationReSigned(index: number, continuationReSigned: string) {
    this.tableData.table_data[index].continuationReSigned = continuationReSigned;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updatePhoto(index: number, photo: string) {
    this.tableData.table_data[index].photo = photo;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateChartsFoundCardDwnld(index: number, chartsFoundCardDwnld: string) {
    this.tableData.table_data[index].chartsFoundCardDwnld = chartsFoundCardDwnld;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDtcoCardCopy(index: number, dtcoCardCopy: string) {
    this.tableData.table_data[index].dtcoCardCopy = dtcoCardCopy;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDriverCpcCardCopy(index: number, driverCpcCardCopy: string) {
    this.tableData.table_data[index].driverCpcCardCopy = driverCpcCardCopy;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

}
