import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { ActionItem } from '../_models/action-item';
import { Data, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-expandable-action-items',
  templateUrl: './expandable-action-items.component.html',
  styleUrls: ['./expandable-action-items.component.css']
})
export class ExpandableActionItemsComponent implements OnInit {

  @Input() questionId;
  @Input() isTable;

  public expandedHeight: any = '120px';
  public collapsedHeight: any = '100px';
  userEmail = '';
  userRole = 0;
  actionItemForms = [];
  savedActionItems = [];
  editAction = false;
  respondActionItemLength = new Set();

  _destroyed$: Subject<void> = new Subject();

  constructor(public dialog: MatDialog, private data: Data, private _restProvide: RestProvider, private _authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.getAllActionItems();

    const user = this._authenticationService.userDetail$.value;
    this.userEmail = user.email;
    this.userRole = user.role;
  }

  stringify = (data): string => JSON.stringify(data);

  getAllActionItems() {
    this._restProvide.getAllActionItems({ card: this.questionId })
      .then((savedActionItems: any[]) => {
        const actionItems = savedActionItems['results'];
        this.savedActionItems = actionItems;

        for (let actionItem of actionItems) {
          // Auditor(1) and Admin (3) at status 2 
          // Or Operator(2) and Depot Manager(5) at status 1 or 6 
          if (([2, 5].includes(this.userRole) && (actionItem.status === 1 || actionItem.status === 6)) ||
            ([1, 3].includes(this.userRole) && actionItem.status === 2 )
          ) { this.respondActionItemLength.add(actionItem.id); }
        }
      });
  }

  getActionItem(id: number) {
    this._restProvide.getActionItemById(id);
  }

  addActionItem() {
    this.actionItemForms.push({ title: '' });
  }

  editActionItem(title: any) {
    title.disabled = false;
    this.editAction = true;
  };

  deleteActionItemById(id: number) {
    this._restProvide.deleteActionItemById(id)
      .then(() => {
        this.savedActionItems = this.savedActionItems.filter(x => x.id !== id);
      });
  }

  actionItemCreated(actionItem, index): void {
    this.savedActionItems.push(actionItem);
    this.actionItemFormDeleted(index);

    const hasId = this.respondActionItemLength.has(actionItem.id);
    debugger
    if (([2, 5].includes(this.userRole) && actionItem.status === 1) ||
      ([1, 3].includes(this.userRole) && actionItem.status === 2)) {
      if (!hasId) { this.respondActionItemLength.add(actionItem.id); }
    }
  }

  actionItemFormDeleted(index: any): void {
    this.actionItemForms.splice(index, 1);
  }

  actionItemDeleted(id: number): void {
    this.savedActionItems = this.savedActionItems.filter(x => x.id !== id);

    const hasId = this.respondActionItemLength.has(id);
    if (hasId) { this.respondActionItemLength.delete(id); }
  }

  actionItemUpdated(actionItem: ActionItem, index): void {
    this.savedActionItems.splice(index, 1, actionItem);
    const hasId = this.respondActionItemLength.has(actionItem.id);
    debugger
    if (([2, 5].includes(this.userRole) && actionItem.status !== 1 && actionItem.status !== 6) ||
      ([1, 3].includes(this.userRole) && actionItem.status !== 2)) {
      if (hasId) { this.respondActionItemLength.delete(actionItem.id); }
    } else if (!hasId) { this.respondActionItemLength.add(actionItem.id); }
  }

  openModalDialog() {
    const _dialogRef = this.dialog.open(ModalComponent, {
      width: '40vw',
      height: '30vh',
      panelClass: 'import-csv-modal-container'
    });
    _dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.actionItemForms = this.actionItemForms.concat(result);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}
