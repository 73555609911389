import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType7} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type7DataSource implements DataSource<TableType7> {
  // {
  // acidentDate:'',
  // reportDate: 'OK\FAIL',
  // reg: 'OK\FAIL',
  // daysToReport: 5,
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType7[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 10) {
      for (let i = this.tableData.table_data.length; i < 10; i++) {
        const tempObj: any = {};
        tempObj.acidentDate = '';
        tempObj.reportDate = '';
        tempObj.reg = '';
        tempObj.daysToReport = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType7[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType7[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.acidentDate = this.tableData.table_data[i].acidentDate;
      tempObj.reportDate = this.tableData.table_data[i].reportDate;
      tempObj.reg = this.tableData.table_data[i].reg;
      tempObj.daysToReport = this.tableData.table_data[i].daysToReport;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateAcidentDate(index: number, acidentDate: string) {
    this.tableData.table_data[index].acidentDate = acidentDate;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateReportDate(index: number, reportDate: string) {
    this.tableData.table_data[index].reportDate = reportDate;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateReg(index: number, reg: string) {
    this.tableData.table_data[index].reg = reg;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDaysToReport(index: number, daysToReport: string) {
    this.tableData.table_data[index].daysToReport = daysToReport;
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
  }


}
