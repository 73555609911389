import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {

    const iconList = [
      { svgIcon: 'two-columns-layout', url: 'assets/img/two-columns-layout.svg' },
      { svgIcon: 'one-columns-layout', url: 'assets/img/one-columns-layout.svg' },
      { svgIcon: 'file-doument', url: 'assets/img/file-document.svg' },
      { svgIcon: 'file-doument-two', url: 'assets/img/file-document-two.svg' },
      { svgIcon: 'menu-dots', url: 'assets/img/dots-vertical.svg' },
      { svgIcon: 'draft', url: 'assets/img/draft.svg' },
      { svgIcon: 'save', url: 'assets/img/save.svg' },
      { svgIcon: 'add', url: 'assets/img/add.svg' },
      { svgIcon: 'edit', url: 'assets/img/edit.svg' },
      { svgIcon: 'delete', url: 'assets/img/delete.svg' },
      { svgIcon: 'message', url: 'assets/img/message.svg' },
      { svgIcon: 'check_circle', url: 'assets/img/check_circle.svg' },
      { svgIcon: 'check_circle_gold', url: 'assets/img/check_circle_gold.svg' },
      { svgIcon: 'clock', url: 'assets/img/clock.svg' },
      { svgIcon: 'cancel', url: 'assets/img/cancel.svg' },
      { svgIcon: 'exclamation', url: 'assets/img/exclamation.svg' },
      { svgIcon: 'pdf', url: 'assets/img/pdf.svg' },
      { svgIcon: 'description', url: 'assets/img/description.svg' },
      { svgIcon: 'sign-arrow-right', url: 'assets/img/sign_arrow_right.svg' },
      { svgIcon: 'keyboard-arrow-down', url: 'assets/img/keyboard_arrow_down.svg' },
      { svgIcon: 'user-contact', url: 'assets/img/user-contact.svg' },
      { svgIcon: 'close-sign', url: 'assets/img/close_sign.svg' },
      { svgIcon: 'done-sign', url: 'assets/img/done_sign.svg' },
      { svgIcon: 'lock-sign', url: 'assets/img/lock_sign.svg' },
      { svgIcon: 'back-sign', url: 'assets/img/keyboard_backspace_sign.svg' },
    ]

    iconList.forEach(({ svgIcon, url }) => this.iconRegistry.addSvgIcon(
      svgIcon, this.sanitizer.bypassSecurityTrustResourceUrl(environment.imagePrefix + url)
    ));

  }

  // tslint:disable-next-line:use-life-cycle-interface
}
