import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType2} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';
import {TimeInputMethods} from '../../_helpers';


export class Type2DataSource implements DataSource<TableType2> {
  // inputJson =[
  //  {
  //    driver:'Wayne Emlyn Nightingale',
  //    date:'2017-05-25',
  //    startWork: 32400000,
  //    finishWork:64800000,
  //    break:1800000,
  //    actionTaken:'',
  //    comments:''
  //  }];

  filter = '';

  // TODO test new tableData.table_data
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType2[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 10) {
      for (let i = this.tableData.table_data.length; i < 10; i++) {
        const tempObj: any = {};
        tempObj.date = '';
        tempObj.driver = '';
        tempObj.startWork = '';
        tempObj.finishWork = '';
        tempObj.break = '1970-01-01T00:30:00.000Z'; // TODO I could get away with 00:30 reducing computation

        tempObj.actionTaken = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  static calcHoursWorked(startDate: number, finishDate: number, breakTaken: number): string {
    const millisWorked = finishDate - startDate - breakTaken;
    return Type2DataSource.millisecondsToElapsedTime(millisWorked);
  }

  static millisecondsToElapsedTime(millis: number): string {
    const hours = Math.trunc(millis / 3600000);
    const min = Math.trunc((millis - (hours * 3600000)) / 60000);
    return hours + 'h:' + min + 'm';

  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType2[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType2[] = [];

    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.date = new Date(this.tableData.table_data[i].date);
      tempObj.driver = this.tableData.table_data[i].driver;


      tempObj.startWork = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].startWork);
      tempObj.finishWork = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].finishWork);
      tempObj.break = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].break);
      tempObj.hoursWorked = TimeInputMethods.timeSubtraction(
        TimeInputMethods.timeDifference(tempObj.startWork, tempObj.finishWork),
        tempObj.break);



      // tempObj.startWork = MaterialTimeConversions.setTimeFromDate(new Date(this.tableData.table_data[i].startWork));
      // tempObj.finishWork = MaterialTimeConversions.setTimeFromDate(new Date(this.tableData.table_data[i].finishWork));
      // tempObj.break = Type2DataSource.millisecondsToElapsedTime(this.tableData.table_data[i].break);
      //
      // tempObj.hoursWorked = Type2DataSource.calcHoursWorked(this.tableData.table_data[i].startWork, this.tableData.table_data[i].finishWork,
      //   this.tableData.table_data[i].break);

      tempObj.actionTaken = this.tableData.table_data[i].actionTaken;
      tempObj.comments = this.tableData.table_data[i].comments;
      tempData.push(tempObj);
    }
    this.tableDisplayData.next(tempData);

  }

  // TODO check that index is always correct ater pagnation
  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
    this.test.apply(this);

  }

  updateDate(index: number, newDate: Date) {
    this.tableData.table_data[index].date = newDate.toISOString();
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    this.data.pushTableUpdate(this.tableData.id);
    this.test2.apply(this);

    // this.loadData();
  }

  updateStartWork(index: number, newTime: string) {
    this.tableData.table_data[index].startWork = TimeInputMethods.timeToISOString(newTime);
    this.data.pushTableUpdate(this.tableData.id);
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
    this.test.apply(this);

  }

  updateFinishWork(index: number, newTime: string) {
    this.tableData.table_data[index].finishWork = TimeInputMethods.timeToISOString(newTime);
    this.data.pushTableUpdate(this.tableData.id);
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
    this.test.apply(this);


  }


  updateActionTaken(index: number, answer: string) {
    this.tableData.table_data[index].actionTaken = answer;
    this.data.pushTableUpdate(this.tableData.id);
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
    this.test2.apply(this);


  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    this.test.apply(this);
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
  }




  test = this.debounce(this.loadData, 5000, false);
  test2 = this.debounce(this.loadData, 10, false);

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce(func, wait, immediate) {
    let timeout;

    // This is the function that is actually executed when
    // the DOM event is triggered.
    return function executedFunction() {
      // Store the context of this and any
      // parameters passed to executedFunction
      const context = this;
      // const args = arguments;

      // The function to be called after
      // the debounce time has elapsed
      const later = function () {
        // null timeout to indicate the debounce ended
        timeout = null;
        console.log('later ' + wait);
        // Call function now if you did not on the leading end
        if (!immediate) {
          func.apply(context);
        }
      };

      // Determine if you should call the function
      // on the leading or trail end
      const callNow = immediate && !timeout;

      // This will reset the waiting every function execution.
      // This is the step that prevents the function from
      // being executed because it will never reach the
      // inside of the previous setTimeout
      clearTimeout(timeout);

      // Restart the debounce waiting period.
      // setTimeout returns a truthy value (it differs in web vs node)
      timeout = setTimeout(later, wait);

      // Call immediately if you're dong a leading
      // end execution
      if (callNow) {
        func.apply(context);
      }
    };
  }
}
