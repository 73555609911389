import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type9DataSource} from './type-9.datasource';

@Component({
  selector: 'app-type-9',
  templateUrl: './type-9.component.html',
  styleUrls: ['./type-9.component.css', '../table-common.css']
})
export class Type9Component implements OnInit {

  displayedColumns = ['driver', 'date', 'hourWorked', 'actionTaken', 'comments'];

  dataSource: Type9DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type9DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDate(index: number, newDate: Date) {
    this.dataSource.updateDate(index, newDate);
  }

  updateHourWorked(index: number, hourWorked: string) {
    this.dataSource.updateHourWorked(index, hourWorked);
  }

  updateActionTaken(index: number, actionTaken: string) {
    this.dataSource.updateActionTaken(index, actionTaken);
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }


}
