import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Data } from '../../_providers';
import { Type5DataSource } from './type-5.datasource';


@Component({
  selector: 'app-type-5',
  templateUrl: './type-5.component.html',
  styleUrls: ['./type-5.component.css', '../table-common.css']
})
export class Type5Component implements OnInit, OnDestroy {

  displayedColumns = ['defect', 'vehReg', 'noteFound', 'noteComplete', 'comments'];

  dataSource: Type5DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new Type5DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateDefect(index: number, defect: string) {
    this.dataSource.updateDefect(index, defect);
  }

  updateVehReg(index: number, vehReg: string) {
    this.dataSource.updateVehReg(index, vehReg);
    // this.loadData();
  }

  updateNoteFound(index: number, noteFound: string) {
    this.dataSource.updateNoteFound(index, noteFound);
  }


  updateNoteComplete(index: number, noteComplete: string) {
    this.dataSource.updateNoteComplete(index, noteComplete);
  }


  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
