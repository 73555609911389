import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoginFailedComponent } from '../dialogs/login-failed/login-failed.component';
import { SpinnerLoadingComponent } from '../spinner-loading/spinner-loading.component';
import { AuthenticationService } from '../_services';

declare var require: (v: string) => any;
const { version, apiVersion } = require('../../../package.json');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

// http://jasonwatmore.com/post/2016/09/29/angular-2-user-registration-and-login-example-tutorial

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  authStatus: 'notauthorised' | 'loggedout' ;
  imagePrefix: string = environment.imagePrefix;
  submitAttempted = false;

  public appVersion: string = version;
  public apiVersion: string = apiVersion;

  error = null;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  async ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.authStatus = this.route.snapshot.queryParams['authStatus'] || '';

    try {
      // If the user has then get additional details on that user /userinfo
      if (!this.authStatus) {
        this.loading = true;
        // Only autologin if the user has not logged themselves out
        this.ssoLogin();
      } else {
        if (this.authStatus === 'notauthorised') {
          this.error = 'Your user is not permitted to login to this app'
        }
      }
    

      } catch (e) {
          console.log(e);
      }

  }

  login() {

    const dialogRef = this.dialog.open(SpinnerLoadingComponent, {
      disableClose: false
    });

    this.loading = true;
    this.error = null;
    this.authenticationService.login(this.model.email, this.model.password)
      .subscribe(
        () => {
          dialogRef.close();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          dialogRef.close();
          console.log(error);
          this.error = error;

          this.dialog.open(LoginFailedComponent, {});
          this.loading = false;
        }
      );
  }

  public async ssoLogin() {
    // const dialogRef = this.dialog.open(SpinnerLoadingComponent, {
    //   disableClose: false
    // });

    this.loading = true;
    this.error = null;

    try {


      this.authenticationService.ssoLogin()
      .then((loggedIn) => {
        if (loggedIn) {
          const redirectUrl = this.returnUrl ? this.returnUrl : '/audits/';
          this.router.navigate([redirectUrl]);
        } else {
          console.log(loggedIn);
          this.error = loggedIn;
  
          this.dialog.open(LoginFailedComponent, {});
          this.loading = false;
      
        }
      } ).catch((e) => {
        console.log(e);
        this.error = e;

        this.dialog.open(LoginFailedComponent, {});
        this.loading = false;
      });

    } catch (e) {
        console.log(e);

    }
  }
}
