import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType5} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type5DataSource implements DataSource<TableType5> {
  // {
  // defect:'',
  // vehReg:'Wayne Emlyn Nightingale',
  // noteFound:'OK\FAIL',
  // noteComplete: 'OK\FAIL',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType5[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 10) {
      for (let i = this.tableData.table_data.length; i < 10; i++) {
        const tempObj: any = {};
        tempObj.defect = '';
        tempObj.vehReg = '';
        tempObj.noteFound = '';
        tempObj.noteComplete = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length; //TODO maybe needs to change
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType5[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType5[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.defect = this.tableData.table_data[i].defect;
      tempObj.vehReg = this.tableData.table_data[i].vehReg;
      tempObj.noteFound = this.tableData.table_data[i].noteFound;
      tempObj.noteComplete = this.tableData.table_data[i].noteComplete;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDefect(index: number, defect: string) {
    this.tableData.table_data[index].defect = defect;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateVehReg(index: number, vehReg: string) {
    this.tableData.table_data[index].vehReg = vehReg;
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
  }

  updateNoteFound(index: number, noteFound: string) {
    this.tableData.table_data[index].noteFound = noteFound;
    this.data.pushTableUpdate(this.tableData.id);
  }


  updateNoteComplete(index: number, noteComplete: string) {
    this.tableData.table_data[index].noteComplete = noteComplete;
    this.data.pushTableUpdate(this.tableData.id);
  }


  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }


}
