import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Data, RestProvider } from '../_providers';

@Component({
  selector: 'app-create-action-item-form',
  templateUrl: './create-action-item-form.component.html',
  styleUrls: ['./create-action-item-form.component.css']
})
export class CreateActionItemFormComponent implements OnInit {

  isLoading = false;

  @Input() questionId = NaN;
  @Input() userRole = 0;
  @Input() actionItemTitle = '';
  @Output() actionItemDeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() actionItemCreated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _restProvider: RestProvider,
    private _data: Data,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  saveActionItem() {
    this.isLoading = true;

    const cardId = this.questionId;

    this._restProvider.saveActionItem(cardId, this.actionItemTitle)
      .then((actionItem) => {
        return this._restProvider.addActionItemComment(actionItem.id, actionItem.title)
          .then(comment => ({ actionItem, comment }));
      })
      .then(({ actionItem, comment }) => {
        return this._restProvider.updateActionItemById(actionItem.card, actionItem.title, actionItem.id, 1)
          .then(updatedActionItem => ({ actionItem, comment, updatedActionItem }));
      })
      .then(({ actionItem, comment, updatedActionItem }) => {
        this.actionItemCreated.emit(updatedActionItem);
        this._data.updateActionItems({ type: 'action_create', card: updatedActionItem.card, id: updatedActionItem.id, role: this.userRole, data: updatedActionItem });
        this.isLoading = false;
      })
      .catch((err) => {
        this.snackBar.open(err, 'close', { verticalPosition: 'bottom' });
        this.isLoading = false;
      });

    // this._restProvider.saveActionItem(this.questionId, this.actionItemTitle)
    //   .then((savedActionItem) => {
    //     console.log('savedActionItem: ', savedActionItem);
    //     this.actionItemTitle = '';
    //     this.actionItemCreated.emit(savedActionItem);

    //     this._data.updateActionItems({
    //       type: 'action_create',
    //       card: savedActionItem.card,
    //       id: savedActionItem.id,
    //       data: savedActionItem,
    //       role: this.userRole
    //     });
    //     this.isLoading = false;
    //   })
    //   .catch((err) => {
    //     this.snackBar.open(err, 'close');
    //     this.isLoading = false;
    //   });
  }

  actionItemDelete() {
    this.actionItemDeleted.emit();
  }

}
