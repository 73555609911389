import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FullScreenImageComponent } from '../dialogs/full-screen-image/full-screen-image.component';
import { MatDialog } from '@angular/material';
import { FileGroup } from '../_models';
import { AuditFile } from '../_models/AuditFile';

@Component({
  selector: 'app-file-group-view',
  templateUrl: './file-group-view.component.html',
  styleUrls: ['./file-group-view.component.css']
})
export class FileGroupViewComponent implements OnInit {

  @Input() file;

  @Input() isEditable: boolean;

  @Input() fileGroupEditable: boolean;
  @Input() submissionClosed: boolean;

  @Input() fileGroup: FileGroup;
  @Output() fileGroupChange: EventEmitter<FileGroup> = new EventEmitter<FileGroup>();

  @Input() selectedImages: Array<number> = [];
  @Output() selectedImagesChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
  @Output() editFileEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() deleteSelectedEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() deleteSelectedGroup: EventEmitter<null> = new EventEmitter<null>();


  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  fullscreenImage(imageIndex) {
    if (!this.fileGroupEditable) {
      this.dialog.open(FullScreenImageComponent, {
        data: {
          fileGroup: this.fileGroup,
          currentImage: imageIndex
        }
      });
    }
  }

  editGroup() {
    this.editFileEvent.emit();
  }

  deleteSelected() {
    this.deleteSelectedEvent.emit();
  }


  public checkBoxClick(event, index) {
    if (event.checked) {
      this.selectedImages.push(index);
    } else {
      for (let j = 0; j < this.selectedImages.length; j++) {
        if (this.selectedImages[j] === index) {
          this.selectedImages.splice(j, 1);
          break;
        }
      }
    }
    this.selectedImagesChange.emit(this.selectedImages);
  }

  public notArchived(file: AuditFile) {
    return !file.archived;
  }
}
