import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DropdownOptions } from '../_models/dropdown-options';
import { AuthenticationService } from '../_services';

declare var require: (v: string) => any;
const { version, apiVersion } = require('../../../package.json');

@Component({
  selector: 'app-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit {

  public appVersion: string = version;
  public apiVersion: string = apiVersion;

  open: boolean = false;

  @Input() role: any;
  @Input() userName: any;
  @Input() customerName: any;

  @Input() set options(value: null | DropdownOptions) {
    if (value === null) { return; }
    this._options = value;
  }

  constructor(private _router: Router, private _authenticationService: AuthenticationService) {
  }

  ngOnInit() { }

  _options: DropdownOptions = {
    items: [],
    seperateLast: true
  };

  logout() {
    this._authenticationService.logout();
    
    setTimeout(() => {
      this._router.navigateByUrl('login');
    }, 1050);
  }

  click_ToggleMenu() {
    this.open = !this.open;
  }

}
