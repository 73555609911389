import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommentModalComponent } from '../comment-modal/comment-modal.component';
import { Data, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.css']
})
export class ActionItemsComponent implements OnInit {

  isLoading = false;
  editAction = false;
  title = '';
  commentLength = 0;

  actionItemStatus = {
    0: { color: '#ffffff', icon: null, text: 'INACTIVE' },
    1: { color: '#000000', icon: 'clock', text: 'AWAITING RESPONSE' },
    2: { color: '#0000ff', icon: 'clock', text: 'REVIEW' },
    3: { color: '#008000', icon: 'check_circle', text: 'APPROVED' },
    4: { color: '#ffa500', icon: 'check_circle', text: 'APPROVED WITH ADVISORIES' },
    5: { color: '#ff0000', icon: 'exclamation', text: 'REJECTED' },
    6: { color: '#5b038a', icon: 'clock', text: 'APPROVED WITH COMMENTS' }
  }

  savedActionItem = null;
  @Input('savedActionItem') set _savedActionItem(actionItem) {
    this.savedActionItem = actionItem;
    this.title = actionItem.title;
  };
  userRole = 0;


  @Output() actionItemDeleted: EventEmitter<number> = new EventEmitter<number>();
  @Output() actionItemUpdated: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private _data: Data,
    private _restProvide: RestProvider,
    private _authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
  }

  deleteActionItemById() {
    this.isLoading = true;
    this._restProvide.deleteActionItemById(this.savedActionItem.id)
      .then(() => {
        this.isLoading = false;
        this.actionItemDeleted.emit(this.savedActionItem.id);
        this._data.updateActionItems({
          type: 'action_delete',
          card: this.savedActionItem.card,
          id: this.savedActionItem.id,
          role: this.userRole
        });
      })
      .catch(() => this.isLoading = false);
  }

  editActionItem() {
    this.editAction = true;
  };

  cancelActionItem() {
    this.editAction = false;
    this.title = this.savedActionItem.title;
  }

  updateActionItemById() {
    this.isLoading = true;
    this._restProvide.updateActionItemById(
      this.savedActionItem.card,
      this.title,
      this.savedActionItem.id,
      this.savedActionItem.status
    )
      .then(actionItem => {
        this.editAction = false;
        this.isLoading = false;
        this.actionItemUpdated.emit(actionItem)
        this._data.updateActionItems({
          type: 'action_update',
          card: actionItem.card,
          id: actionItem.id,
          data: actionItem,
          role: this.userRole
        });
      })
      .catch(() => this.isLoading = false);
  }

  openCommentModal() {
    const _dialogRef = this.dialog.open(CommentModalComponent, {
      disableClose: true,
      panelClass: 'comment-modal-container',
      data: {
        actionItem: this.savedActionItem,
        userRole: this.userRole,
      }
    });

    _dialogRef.afterClosed().subscribe(result => {
      console.log('result: ', result);
      if (result.isStatusUpdated) {
        this.savedActionItem = result.actionItem;
        this.actionItemUpdated.emit(result.actionItem);
      }
    });
  }
}
