import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UnansweredQuestionsDialogComponent} from '../unanswered-questions-dialog/unanswered-questions-dialog.component';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.css']
})
export class LoginFailedComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {}

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}
