import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UnansweredQuestionsDialogComponent} from '../unanswered-questions-dialog/unanswered-questions-dialog.component';

@Component({
  selector: 'app-full-screen-image',
  templateUrl: './full-screen-image.component.html',
  styleUrls: ['./full-screen-image.component.css']
})
export class FullScreenImageComponent implements OnInit {
  imageSrc: string;

  constructor(public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit() {
    this.imageSrc = this.dialogData.fileGroup.files.filter(this.notArchived)[this.dialogData.currentImage].file;
  }

  moveRight() {
    this.dialogData.currentImage++;
    if (this.dialogData.fileGroup.files.filter(this.notArchived).length > this.dialogData.currentImage)
    {
      this.imageSrc = this.dialogData.fileGroup.files.filter(this.notArchived)[this.dialogData.currentImage].file;
    }else
    {
      this.dialogData.currentImage = 0;
      this.imageSrc = this.dialogData.fileGroup.files.filter(this.notArchived)[this.dialogData.currentImage].file;

    }
  }

  moveLeft() {
    this.dialogData.currentImage--;
    if ( this.dialogData.currentImage > -1)
    {
      this.imageSrc = this.dialogData.fileGroup.files.filter(this.notArchived)[this.dialogData.currentImage].file;
    }else
    {
      this.dialogData.currentImage = this.dialogData.fileGroup.files.filter(this.notArchived).length - 1;
      this.imageSrc = this.dialogData.fileGroup.files.filter(this.notArchived)[this.dialogData.currentImage].file;

    }
  }

  notArchived(file)
  {
    return !file.archived;
  }

  close() {
    this.dialogRef.close();
  }
}
