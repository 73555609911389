///<reference path="../_providers/rest/rest.ts"/>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Data, GlobalSettings, RestProvider } from '../_providers';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { MessageDialogComponent } from '../_dialogs/message-dialog/message-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FileGroupEditorComponent } from '../dialogs/file-group-editor/file-group-editor.component';


@Component({
  selector: 'app-new-file-upload',
  templateUrl: './new-file-upload.component.html',
  styleUrls: ['./new-file-upload.component.css']
})
export class NewFileUploadComponent implements OnInit {
  public displayClass = 'top';

  fileList: any = [];
  url: string;
  myUrl: number;
  filesToUpload: any;
  public groupDescription = '';

  preperingUpload = false;
  numFilesToUpload = 0;
  numFilesUploaded = 0;
  showFileHint = false;
  submitAttempted = false;
  retryToUploadFailed = false;
  retryID = -1;
  fileType = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalSettings: GlobalSettings,
    public http: HttpClient,
    private data: Data,
    private _location: Location,
    private restProvider: RestProvider,
    public dialog: MatDialog,
  ) {

    this.globalSettings.isFullScreen$.subscribe(
      () => {
        this.navigate();
      });

    // should come up with a not so low level method to tell component position
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();

      }
    });
    // this code only runs when it is initialised so we can determine the components position from the url used to load it
    this.myUrl = (router.url.match(/\//g) || []).length;

  }

  ngOnInit() {
  }

  onSelectFile(event) { // called each time file input changes
    let filesToBig = [];
    this.fileList = [];
    this.filesToUpload = [];
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {

        if (event.target.files[i].size > 10000000 /*10MB limit*/ - 200 /*wiggle room*/) {
          filesToBig.push(event.target.files[i].name);
        } else {
          this.filesToUpload.push(event.target.files[i]);
          const reader = new FileReader();

          if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(event.target.files[i].name)) {
            reader.onload = (FileData) => {
              const tempObj: any = {};
              const target: any = FileData.target; // <-- This (any) will tell compiler to shut up!
              tempObj.url = target.result;
              tempObj.name = event.target.files[i].name;
              tempObj.type = event.target.files[i].type;

              console.log("event.target.files[i]", event.target.files[i]);

              this.fileType = tempObj.type.split("/")[1];

              this.fileList.push(tempObj);
            };

            console.log("event.target.files[i]", event.target.files[i]);

            reader.readAsDataURL(event.target.files[i]); // read file as data url
          } else {
            const tempObj: any = {};
            tempObj.url = '';
            tempObj.name = event.target.files[i].name;
            tempObj.type = event.target.files[i].type;

            this.fileType = tempObj.type.split("/")[1];

            this.fileList.push(tempObj);
          }
        }
      }
    }

    if (filesToBig.length > 0) {
      let contentText = '';
      for (let i = 0; i < filesToBig.length; i++) {
        contentText += filesToBig[i] + '\r\n';
      }
      this.dialog.open(MessageDialogComponent, {
        data: {
          headerText: 'The following files exceed the 10MB limit per file.',
          contentText: contentText
        }
      });
    }
  }


  // updateDescription(value) {
  //   this.groupDescription = value;
  // }

  // TODO debate if this should go though the global data file instead of straight to the rest provider
  upload(validForm) {
    console.log('validForm');
    console.log(validForm);
    console.log(this.fileList);
    if (this.fileList.length !== 0 && this.groupDescription) {
      if (!this.retryToUploadFailed) {
        this.preperingUpload = true;
        const files: Array<File> = this.filesToUpload;

        this.restProvider.createFileGroup(this.groupDescription, this.data.currentQuestionID)
          .then((result: any) => {
            this.numFilesToUpload = files.length;
            this.retryID = result.id;
            this.uploadFiles(files, result.id);
          }, (err) => {
            console.log(err);
          });
      } else {
        const files: Array<File> = this.filesToUpload;
        this.uploadFiles(files, this.retryID);
      }
    } else {
      this.showFileHint = true;
    }

  }

  uploadFiles(fileArray, fileGroupID) {
    this.numFilesToUpload = fileArray.length;
    this.preperingUpload = false;
    const failedList = [];

    for (let i = 0; i < fileArray.length; i++) {
      const file: File = fileArray[i];

      this.restProvider.uploadAttachment(file.name, file, fileGroupID)
        .then((result) => {
          this.numFilesUploaded++;
          if (this.numFilesToUpload === this.numFilesUploaded) {
            if (failedList.length > 0) {
              this.onFailedUploadCompleate(failedList);
            } else {
              this.onUploadCompleate();
            }
          }
        }, (err) => {
          failedList.push(i);
          this.numFilesUploaded++;
          if (this.numFilesToUpload === this.numFilesUploaded) {

            this.onFailedUploadCompleate(failedList);
          }
          console.log(err);
        });
    }
  }

  onUploadCompleate() {
    this.numFilesUploaded = 0;
    this.numFilesToUpload = 0; // resets the view
    this.data.refreshAttachmentsForCard(this.data.currentQuestion);
    this._location.back();
  }

  onFailedUploadCompleate(failedList) {
    this.dialog.open(MessageDialogComponent, {
      data: {
        headerText: 'Error uploading ' + failedList.length + ' of ' + this.numFilesToUpload + ' files.',
        // tslint:disable-next-line:max-line-length
        contentText: 'Failed to upload the files remaining. All other files have been uploaded correctly, and the file group has been created. \r\nPlease click retry to attempt to retry uploading the failed files.'
      }
    });
    this.numFilesUploaded = 0;
    this.numFilesToUpload = 0; // resets the view
    const tempFileList = [];
    const tempFilesToUpload = [];
    for (let i = 0; i < failedList.length; i++) {
      tempFileList.push(this.fileList[failedList[i]]);
      tempFilesToUpload.push(this.filesToUpload[failedList[i]]);
    }

    this.fileList = tempFileList;
    this.filesToUpload = tempFilesToUpload;
    this.retryToUploadFailed = true;
  }


  navigate() {
    if (this.globalSettings.isFullScreen.value) {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'full-top';
      } else {
        this.displayClass = 'gone';
      }
    } else {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'top';
      } else if ((this.router.url.match(/\//g) || []).length === this.myUrl + 1) {
        this.displayClass = 'bottom';
      } else {
        this.displayClass = 'gone';
      }
    }
  }
}


