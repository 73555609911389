import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RestProvider} from '../_providers';
import {MessageDialogComponent} from '../_dialogs/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material';
import {Router} from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  submitAttempted = false;
  email: string;
  imagePrefix: string = environment.imagePrefix;


  constructor(private rest: RestProvider, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
  }

  resetEmail() {

    this.rest.resetPassword(this.email, false).then(data => {
      console.log(data);
      this.dialog.open(MessageDialogComponent, {
        data: {
          headerText: 'Success',
          contentText: 'Reset email request sent.'
        }
      });
      this.dialog.afterAllClosed.subscribe(result => {
        this.router.navigate(['/login']);
      });
    }, reject => {
      this.dialog.open(MessageDialogComponent, {
        data: {
          headerText: 'Error',
          contentText: reject.error.message
        }
      });

    });

  }
}
