import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AuditFile} from '../../_models/AuditFile';
import {Data, RestProvider} from '../../_providers';
import {FileGroup} from '../../_models';
import {LoginFailedComponent} from '../login-failed/login-failed.component';
import {DeleteFileGroupComponent} from '../delete-file-group/delete-file-group.component';

@Component({
  selector: 'app-file-group-editor',
  templateUrl: './file-group-editor.component.html',
  styleUrls: ['./file-group-editor.component.css']
})
export class FileGroupEditorComponent implements OnInit {
  // used to allow a diff of changes to reduce network calls, no need for original main as that is stored in dialogData
  uneditedFileGroupOnRHS: any;
  editedFileGroupOnRHS: any;
  split = false;
  editedFileGroupOnLHS: any;
  secondaryImagesSelected: Array<number> = [];
  mainImagesSelected: Array<number> = [];

  constructor(public dialogRef: MatDialogRef<FileGroupEditorComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public restProvider: RestProvider,
              public  data: Data) {
    // disable closing by clicking away, this also disables the escape key
    this.dialogRef.disableClose = true;
    this.editedFileGroupOnLHS = this.copyFileGroup(this.dialogData.fileGroup);
    this.uneditedFileGroupOnRHS = {};
    this.editedFileGroupOnRHS = {};
    this.editedFileGroupOnRHS.id = -1;
    this.editedFileGroupOnRHS.files = [];
    this.editedFileGroupOnRHS.description = '';
    this.editedFileGroupOnRHS.archived = false;
    this.editedFileGroupOnRHS.card = this.editedFileGroupOnLHS.card;

  }

  // enable the escape key to close the dialog
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  copyFileGroup(fileGroup): FileGroup {
    const copiedFileGroup: any = {};
    copiedFileGroup.id = fileGroup.id;
    copiedFileGroup.description = fileGroup.description;
    copiedFileGroup.created = fileGroup.created;
    copiedFileGroup.archived = fileGroup.archived;
    copiedFileGroup.card = fileGroup.card;

    copiedFileGroup.files = [];
    for (let i = 0; i < fileGroup.files.length; i++) {
      const tempFile: any = {};
      tempFile.id = fileGroup.files[i].id;
      tempFile.name = fileGroup.files[i].name;
      tempFile.uploaded_date = fileGroup.files[i].uploaded_date;
      tempFile.file = fileGroup.files[i].file;
      tempFile.archived = fileGroup.files[i].archived;
      tempFile.group = fileGroup.files[i].group;
      tempFile.selected = fileGroup.files[i].selected;
      copiedFileGroup.files.push(tempFile);
    }
    return copiedFileGroup;
  }

  closeAndSave() {
    const returnArray: Array<FileGroup> = [];

    if (this.editedFileGroupOnLHS.description !== this.dialogData.fileGroup.description) {
      this.restProvider.updateFileGroupDescription(this.editedFileGroupOnLHS);
    }
    this.updateFileForGroup(this.editedFileGroupOnLHS, this.dialogData.fileGroup);
    returnArray.push(this.copyFileGroup(this.editedFileGroupOnLHS));

    if (!(Object.keys(this.uneditedFileGroupOnRHS).length === 0 && this.uneditedFileGroupOnRHS.constructor === Object)
      || this.split) {
      if (this.split) {
        this.restProvider.createFileGroup(this.editedFileGroupOnRHS.description, this.editedFileGroupOnRHS.card)
          .then((result: any) => {
            this.editedFileGroupOnRHS.id = result.id;
            this.editedFileGroupOnRHS.card = this.data.currentQuestionID; // todo might be better to just get it from other file group
            this.updateFileForGroup(this.editedFileGroupOnRHS, this.uneditedFileGroupOnRHS);
            returnArray.push(this.copyFileGroup(this.editedFileGroupOnRHS));
          }, (err) => {
            console.log(err);
          });
      } else {
        if (this.editedFileGroupOnRHS.description !== this.uneditedFileGroupOnRHS.description) {
          this.restProvider.updateFileGroupDescription(this.editedFileGroupOnRHS);
        }
        this.updateFileForGroup(this.editedFileGroupOnRHS, this.uneditedFileGroupOnRHS);
        returnArray.push(this.copyFileGroup(this.editedFileGroupOnRHS));
      }
    }
    this.dialogRef.close(returnArray);
  }


  updateFileForGroup(fileGroup: FileGroup, originalFileGroup: FileGroup) {
    for (let k = 0; k < fileGroup.files.length; k++) {
      // todo double check that server is no longer returning archives files because this will generate unnecessary calls if it does
      if (/*fileGroup.files[k].archived*/ this.wasArchived(fileGroup.files[k], k, originalFileGroup) || !this.isInOriginalGroup(
        fileGroup.files[k], originalFileGroup)) {
        fileGroup.files[k].group = fileGroup.id;
        this.restProvider.updateFile(fileGroup.files[k])
          .then((result) => {
            // todo work out how to convey this to the user and if i want to change the group id of the image here instead of before to
            // reduce random strange erroneous views
          }, (err) => {
            console.log(err);
          });
      }
    }
  }

  // this is just a stand in method until the backend stops returning archived files. As such its just build for speed not accuracy.
  // TODO remove when backend no longer returns archived files
  wasArchived(file: AuditFile, fileIndex: number, originalFileGroup: FileGroup) {
    return file.archived && originalFileGroup.files.length > fileIndex && !originalFileGroup.files[fileIndex].archived;
  }

  isInOriginalGroup(file: AuditFile, originalFileGroup: FileGroup) {
    // sadly cant guarantee order as someone could move the file back and forth putting it at the end
    // TODO maybe make the order more predictable for the user
    // luckily additional calls wont break anything so this method may return false when it is in the group, but it wont return true
    // if it false. To reduce computation I am stopping the loop once I have exceeded the id number

    // original file group is {} eg it doesn't exist as its a new group
    if (Object.keys(originalFileGroup).length === 0 && originalFileGroup.constructor === Object) {
      return false;
    }

    for (let i = 0; i < originalFileGroup.files.length && file.id >= originalFileGroup.files[i].id; i++) {
      if (file.id === originalFileGroup.files[i].id) {
        return true;
      }
    }
    return false;
  }


  close() {
    this.dialogRef.close();
  }

  moveToSecondary() {
    this.mainImagesSelected.sort((a, b) => b - a); // Sorts array into descending order so i can delete
    for (let i = 0; i < this.mainImagesSelected.length; i++) {
      const tempFile = this.editedFileGroupOnLHS.files.filter(this.notArchived)[this.mainImagesSelected[i]];
      this.editedFileGroupOnRHS.files.push(tempFile);
      // this is not pretty i hate this solution but any other way to cover up arched without a filter gave some worse error and solving it
      // would have required even more computation to reduce the loop a little i set my start point at the index received from the filter
      // list as i can guarantee my file will be after that. At least i hope i can guarantee it
      for (let j = this.mainImagesSelected[i]; j < this.editedFileGroupOnLHS.files.length; j++) {
        console.log(tempFile);
        console.log(this.editedFileGroupOnLHS.files[j]);
        if (tempFile.id === this.editedFileGroupOnLHS.files[j].id) {
          this.editedFileGroupOnLHS.files.splice(j, 1);
          console.log('deleted');

        }
      }
    }
    this.mainImagesSelected = [];
  }

  moveToMain() {

    this.secondaryImagesSelected.sort((a, b) => b - a); // Sorts array into descending order so i can delete
    for (let i = 0; i < this.secondaryImagesSelected.length; i++) {
      // this.mainFileGroup.files.push(this.secondaryFileGroup.files[this.secondaryImagesSelected[i]]);
      // this.secondaryFileGroup.files.splice(this.secondaryImagesSelected[i], 1);

      const tempFile = this.editedFileGroupOnRHS.files.filter(this.notArchived)[this.secondaryImagesSelected[i]];
      this.editedFileGroupOnLHS.files.push(tempFile);
      // same problem as with move to secondary
      for (let j = this.secondaryImagesSelected[i]; j < this.editedFileGroupOnRHS.files.length; j++) {
        if (tempFile.id === this.editedFileGroupOnRHS.files[j].id) {
          this.editedFileGroupOnRHS.files.splice(j, 1);
        }
      }
    }
    this.secondaryImagesSelected = [];
  }

  selectedMergeGroup(fileGroup: FileGroup) {
    if (fileGroup.id !== this.editedFileGroupOnLHS.id) {
      this.uneditedFileGroupOnRHS = fileGroup;
      this.editedFileGroupOnRHS = this.copyFileGroup(fileGroup);
    }
  }

  editedMainSelected() {
    if (this.secondaryImagesSelected.length > 0) { // debatable if its worth it
      this.secondaryImagesSelected = [];
    }
  }


  editedSecondarySelected() {
    if (this.mainImagesSelected.length > 0) { // debatable if its worth it
      this.mainImagesSelected = [];
    }
  }

  public notArchived(file: AuditFile) {
    return !file.archived;
  }

  deleteFilesFromGroup(fileGroup: FileGroup, selectedImages: Array<number>) {
    // this.selectedImages.sort((a, b) => b - a); // Sorts array into descending order // not needed as this is not destructive
    for (let i = 0; i < selectedImages.length; i++) {
      const idToFind = fileGroup.files.filter(this.notArchived)[selectedImages[i]].id;
      // same problem as with move to secondary
      for (let j = selectedImages[i]; j < fileGroup.files.length; j++) {
        if (idToFind === fileGroup.files[j].id) {
          fileGroup.files[j].archived = true;
        }
      }
    }
  }

  public deleteFileGroup() {
    console.log(this.editedFileGroupOnLHS.id);
    this.restProvider.deleteGroup(this.editedFileGroupOnLHS).then(() => {
      this.dialogData.fileGroup.archived = true;
      this.close();
    }, (err) => {
      console.log(err);
    });
  }

  openConfirmationDialog() {
    const conf = this.dialog.open(DeleteFileGroupComponent, {
      data: {
        fileGroup: this.editedFileGroupOnLHS,
      }
    });
    conf.afterClosed().subscribe(result => {
      if (result) {
      this.deleteFileGroup();
      }
    });
  }
}
