import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {
  headerText = '';
  contentText = '';

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    if ('headerText' in dialogData) {
      this.headerText = dialogData.headerText;
    }
    if ('contentText' in dialogData) {
      this.contentText = dialogData.contentText;
    }

  }

  ngOnInit() {
  }

}
