import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type7DataSource} from './type-7.datasource';

@Component({
  selector: 'app-type-7',
  templateUrl: './type-7.component.html',
  styleUrls: ['./type-7.component.css', '../table-common.css']
})
export class Type7Component implements OnInit {

  displayedColumns = ['acidentDate', 'reportDate', 'reg', 'daysToReport', 'comments'];

  dataSource: Type7DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type7DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateAcidentDate(index: number, acidentDate: string) {
    this.dataSource.updateAcidentDate(index, acidentDate);
  }

  updateReportDate(index: number, reportDate: string) {
    this.dataSource.updateReportDate(index, reportDate);
  }

  updateReg(index: number, reg: string) {
    this.dataSource.updateReg(index, reg);
  }

  updateDaysToReport(index: number, daysToReport: string) {
    this.dataSource.updateDaysToReport(index, daysToReport);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }


}
