import {Injectable} from '@angular/core';

import {BehaviorSubject} from 'rxjs';

@Injectable()

export class GlobalSettings {


  // Observable string sources
  public isFullScreen = new BehaviorSubject<boolean>(false);

  // Observable string streams
  isFullScreen$ = this.isFullScreen.asObservable();

  // Service message commands
  toggleFullscreen() {
    this.isFullScreen.next(!this.isFullScreen.getValue());
  }

}

