import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Data, GlobalSettings} from '../_providers';
import {SectionListService} from '../section-list/section-list.service';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material';
import {QuestionListService} from './question-list.service';
import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.css'],
  providers: [QuestionListService]
})
export class QuestionListComponent implements OnInit, OnDestroy {
  myUrl: number;
  public section: any;
  public displayClass = 'top';
  routerSubscription: Subscription;
  userRole = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public data: Data,
    private sectionListService: SectionListService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private questionListService: QuestionListService,
    private _authenticationService: AuthenticationService,
    private globalSettings: GlobalSettings) {
    this.globalSettings.isFullScreen$.subscribe(
      () => {
        this.navigate();
      });

    // should come up with a not so low level method to tell component position
    this.routerSubscription = router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();

      }
    });

    this.myUrl = (router.url.match(/\//g) || []).length; // this code only runs when it is initialised so we cadeterminein the
    // components position from the url used to load it
    this.section = this.data.currentSection;
  }

  navigate() {
    if (this.globalSettings.isFullScreen.value) {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'full-top';
        // }else if ((this.router.url.match(/\//g) || []).length == this.myUrl+1){
        // this.displayClass= "bottom";
      } else {
        this.displayClass = 'gone';
      }
    } else {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'top';
      } else if ((this.router.url.match(/\//g) || []).length === this.myUrl + 1) {
        this.displayClass = 'bottom';
      } else {
        this.displayClass = 'gone';
      }
    }
  }

  ngOnInit() {
    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
  }


  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.routerSubscription.unsubscribe(); // There used to be no need to unsubscribe but it suddenly started to error out without it

  }
}
