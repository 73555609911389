import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType13} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type13DataSource implements DataSource<TableType13> {
  // {
  // date:'',
  // driver: '',
  // infringementDate: '',
  // infringement: '',
  // signedLetterfoundInFile:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType13[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 5) {
      for (let i = this.tableData.table_data.length; i < 5; i++) {
        const tempObj: any = {};
        tempObj.date = '';
        tempObj.driver = '';
        tempObj.infringementDate = '';
        tempObj.infringement = '';
        tempObj.signedLetterFoundInFile = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType13[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType13[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.date = this.tableData.table_data[i].date;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.infringementDate = this.tableData.table_data[i].infringementDate;
      tempObj.infringement = this.tableData.table_data[i].infringement;
      tempObj.signedLetterFoundInFile = this.tableData.table_data[i].signedLetterFoundInFile;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDate(index: number, date: string) {
    this.tableData.table_data[index].date = date;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateInfringementDate(index: number, infringementDate: string) {
    this.tableData.table_data[index].infringementDate = infringementDate;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateInfringement(index: number, infringement: string) {
    this.tableData.table_data[index].infringement = infringement;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateSignedLetterFoundInFile(index: number, signedLetterFoundInFile: string) {
    this.tableData.table_data[index].signedLetterFoundInFile = signedLetterFoundInFile;
    this.data.pushTableUpdate(this.tableData.id);
  }


}
