import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CardSet, IUserObject, Submission } from '../_models';
import { AuditFile } from '../_models/AuditFile';
import { Data, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})


export class QuestionComponent implements OnInit, OnDestroy {

  @Input() inputData: CardSet;
  public question: CardSet;
  public commentsEdited = false;

  userRole = 0;
  questionNote = '';
  questionNoteDepotManager = '';
  userDetail: IUserObject | null = null;
  passedCompliance: boolean = false;

  _destroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public snackBar: MatSnackBar,
    private data: Data,
    private restProvider: RestProvider,
    public dialog: MatDialog,
    private _authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.question = this.inputData;

    this.questionNote = this.question.comments;

    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
    this.userDetail = user;
    this.complianceCalc();
  }

  saveQuestionGeneralNote() {
    this.data.updateQuestionAnswerByID(this.question, this.question.answer, this.userRole)
      .then((result) => { 
        this.commentsEdited = false; });
  }

  

  public attachFiles(question, event) {
    event.stopPropagation();
    this.data.switchQuestion(question.id);
    const nav = 'files';
    this.router.navigate([nav], { relativeTo: this.route });
  }

  public complianceCalc() {
    // A calculation box is added which uses the above information to display the following as a percentage;  
    // (Number of working days - Number of EC hours infringements) / Number of working days
    if(this.inputData.number_of_working_days && this.inputData.number_of_infringements !== null) {
      this.commentsEdited = true;
      const result = (this.inputData.number_of_working_days - this.inputData.number_of_infringements) / this.inputData.number_of_working_days * 100;
      this.inputData.compliance_ratio = Number(result.toFixed(1));
      this.passedCompliance = this.inputData.compliance_ratio >= this.inputData.minimum_compliance;
      // Update the question data
      this.data.updateQuestionAnswerByID(this.question, this.question.answer, this.userRole);
    }
  }

  async updateAnswer(answer) {
    this.data.updateQuestionAnswerByID(this.question, answer, this.userRole);
    // if(this.data.currentSubmission.percent === 100){
    //   this.data.toggleLoading(true)
    //   let alert = null;
    //   await this.restProvider.closeSubmissionByID(this.data.currentSubmission.id).then(data => {
    //     alert = {
    //       status: 'success',
    //       message: 'Submission closed successfully...',
    //     };
    //     this.data.moveSubmisionDataAddSectionMetaData(this.data.currentSubmission, data as Submission);
    //     // this.router.navigate(['/audits', this.data.currentRegionID, this.data.currentYear], {
    //     //   // relativeTo: this.route,
    //     //   // queryParamsHandling: 'merge', // remove to replace all query params by provided
    //     // });
    //     this.data.toggleLoading(false)
    //     this.router.navigate([''])
    //   }).catch(err => (alert = {
    //     status: 'error',
    //     message: err.error.error || 'Submission has unanswered action items!'
    //   }));
    //   if (alert.status === 'success') {
    //     this.snackBar.open(alert.message, '', {
    //       duration: 3000,
    //       verticalPosition: 'bottom'
    //     });
    //   } else {
    //     this.snackBar.open(alert.message, 'close', { verticalPosition: 'bottom' });
    //   }
    // }
  }

  selectedAnswer(event) {
    event.stopPropagation();
  }

  // saveComments(event: Event) {
  //   event.srcElement.setAttribute('disabled', 'true');

  //   this.data.updateQuestionAnswerByID(this.question.id, this.question.answer, this.inputData.comments).then((result) => {
  //     // event.srcElement.removeAttribute('disabled');
  //     this.commentsEdited = false;
  //   }, (err) => {
  //     console.log(err);
  //     this.commentsEdited = true;
  //     alert('Failed to save comment please try again');
  //     // TODO Should display error to user in a better way
  //   });

  // }

  public notArchived(file: AuditFile) {
    return !file.archived;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
