import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Data } from '../../_providers';
import { Type3DataSource } from './type-3.datasource';


@Component({
  selector: 'app-type-3',
  templateUrl: './type-3.component.html',
  styleUrls: ['./type-3.component.css', '../table-common.css']
})
export class Type3Component implements OnInit, OnDestroy {

  displayedColumns = ['diskFound', 'driver', 'dateUsed', 'diskComp', 'licenceOkay', 'continuationReSigned', 'photo', 'chartsFoundCardDwnld', 'dtcoCardCopy', 'driverCpcCardCopy', 'comments'];
  // inputJson =[
  // {
  //   diskFound:'',
  //   driver:'Wayne Emlyn Nightingale',
  //   dateUsed:'2017-05-25',
  //   discComp:'',
  //   licenceOkey:'',
  //   continuationReSigned:'',
  //   photo:'',
  //   chartsFoundCardDwnld:'',
  //   dtcoCardCopy:'',
  //   driverCpcCardCopy:'',
  //   comments:''
  // }];

  dataSource: Type3DataSource;

  _destroyed$: Subject<void> = new Subject();

  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public constructor(private data: Data, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new Type3DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDiskFound(index: number, diskFound: string) {
    this.dataSource.updateDiskFound(index, diskFound);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDateUsed(index: number, newDate: Date) {
    this.dataSource.updateDateUsed(index, newDate);
  }

  updateDiskComp(index: number, diskComp: string) {
    this.dataSource.updateDiskComp(index, diskComp);
  }

  updateLicenceOkay(index: number, licenceOkay: string) {
    this.dataSource.updateLicenceOkay(index, licenceOkay);
  }

  updateContinuationReSigned(index: number, continuationReSigned: string) {
    this.dataSource.updateContinuationReSigned(index, continuationReSigned);
  }

  updatePhoto(index: number, photo: string) {
    this.dataSource.updatePhoto(index, photo);
  }

  updateChartsFoundCardDwnld(index: number, chartsFoundCardDwnld: string) {
    this.dataSource.updateChartsFoundCardDwnld(index, chartsFoundCardDwnld);
  }

  updateDtcoCardCopy(index: number, dtcoCardCopy: string) {
    this.dataSource.updateDtcoCardCopy(index, dtcoCardCopy);
  }

  updateDriverCpcCardCopy(index: number, driverCpcCardCopy: string) {
    this.dataSource.updateDriverCpcCardCopy(index, driverCpcCardCopy);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}




