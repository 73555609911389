import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { unescape } from 'querystring';
import { AuditFile } from '../../_models/AuditFile';
import { ActionItem } from '../../_models/action-item';
import { Audit, CardSet, IUser, IUserObject, UserInfoProfileModel } from '../../_models';
import { ActionItemComment } from '../../_models/action-item-comments';


/*
 Generated class for the RestProvider provider.

 See https://angular.io/guide/dependency-injection for more info on _providers
 and Angular DI.
 */
@Injectable()
export class RestProvider {
  apiUrl = environment.apiUrl;
  token = '';
  tables;

  constructor(public http: HttpClient, private router: Router) {
    console.log('This is RestProvider Provider');
  }

  async getUserInfo(token: string): Promise<any> {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders
    };
    const authUrl = environment.authRedirectLoginUrl;
    const data = await fetch(authUrl + "userinfo", requestOptions);
    // const data = await fetch(`${environment.urlFMT}/fmt/user/get_current_user_with_image/`, requestOptions);

    return await data.json()
  }

  async getCurrentUser(token: string, handleErrors: boolean = true): Promise<IUserObject> {
    // return new Promise((resolve, reject) => {
    //   const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Authorization'});
    //   headers.append("Authorization", `Bearer ${token}`);
    //   this.http.get<IUser>(this.apiUrl + '/auth/current_user/', { headers })
    //     .subscribe(res => {
    //       resolve(res);
    //     }, (err) => {
    //       if (!handleErrors || !this.handleCommonErrors(err)) {
    //         reject(err);
    //       }
    //     });
    // });

    var headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: 'GET',
      headers: headers
    };
    const url = environment.authRedirectLoginUrl;
    const data = await fetch(this.apiUrl + '/auth/current_user/', requestOptions);
    // const data = await fetch(`${environment.urlFMT}/fmt/user/get_current_user_with_image/`, requestOptions);
    
    return await data.json()
  }

  encodeQuery(queryObject) {
    return Object.entries(queryObject)
      .filter(([key, value]) => typeof value !== 'undefined')
      .map(([key, value]: any) => encodeURIComponent(key) + (value != null ? '=' + encodeURIComponent(value) : ''))
      .join('&')
  }

  resetPassword(email: string, handleErrors: boolean = true) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + '/reset_password/', { email: email }, { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  newPassword(password: string, token: string, handleErrors: boolean = true) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return new Promise((resolve, reject) => {
      this.http.put(this.apiUrl + '/reset_password_key/', { password: password, token: token }, { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {

          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
            console.log('hey2');

          }
        });
    });
  }

  getAuditYears() {
    return new Promise<number[]>((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get<any[]>(this.apiUrl + '/audit' + '/audit_years/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  // audit/regions/
  getRegionList(handleErrors: boolean = true): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get<any[]>(this.apiUrl + '/audit' + '/regions/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  getAuditList(regionID: number, year: number, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/audits/?region=' + regionID + '&year=' + year, { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  getAuditById(id: number, handleErrors: boolean = true) {
    return new Promise<Audit>((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get<Audit>(this.apiUrl + '/audit' + '/audits/' + id + '/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  getSubmissionById(id: number, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/submissions/' + id + '/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }


  getCardById(id: number, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/cards/' + id + '/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
    });
  }

  attachFiles(id: number, text: string, section: number, answer: string, comments: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const body = {
      id, text, section, answer, comments
    };

    return new Promise((resolve, reject) => {
      this.http.put<any>(this.apiUrl + '/audit/cards/' + id + '/', JSON.stringify(body), { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  getAttachmentsById(id: number, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/filegroups/' + id + '/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  updateRank(auditID: string, year: number, handleErrors: boolean = true) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + '/audit/update_ranks/', { auditID: auditID, year: year }, { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }


  closeSubmissionByID(submissionID: number, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/submissions/' + submissionID + '/close/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }


  getSubmissionDeadlineByID(submission: any, handleErrors: boolean = true) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/set_deadline/', { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }


  updateSubmissionByIDForDashBoard(submission: any, handleErrors: boolean = true) {
    const uploadSubmission: any = {};
    uploadSubmission.dash_points_awarded = submission.dash_points_awarded;
    uploadSubmission.dash_percent = submission.dash_percent;
    uploadSubmission.dash_questions_answered = submission.dash_questions_answered;
    uploadSubmission.dash_points_allocated = submission.dash_points_allocated;
    uploadSubmission.dash_lci_count = submission.dash_lci_count;
    uploadSubmission.status = submission.status;
    if (submission.action_list) {
      uploadSubmission.action_list = submission.action_list_text;
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/', JSON.stringify(uploadSubmission), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  updateSubmisionActionList(submission: any, actionList: string, handleErrors: boolean = true) {
    const uploadSubmission: any = {};
    uploadSubmission.action_list = actionList;


    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/', JSON.stringify(uploadSubmission), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  setAuditDeadlineNotification(submission: any, date: string, handleErrors: boolean = true) {

    const formData: FormData = new FormData();
    formData.append('action_items_deadline', date);


    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/', formData)
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  submitSubmissionSignOffDM(submission: any, handleErrors: boolean = true) {

    const blob = RestProvider.base64ImageToBlob(submission.rtm_signoff_signature);


    const formData: FormData = new FormData();
    formData.append('rtm_signoff_signature', blob, RestProvider.fileNameRandomiser('rtmSignature.png'));
    formData.append('rtm_signoff_date', submission.rtm_signoff_date);
    formData.append('rtm_signoff_name', submission.rtm_signoff_name);
    formData.append('status', submission.status);

    const headers = new HttpHeaders({ enctype: 'multipart/form-data'})
    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/', formData, 
        {headers})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }


  submitSubmissionSignOffAuditor(submission: any, handleErrors: boolean = true) {

    const blob = RestProvider.base64ImageToBlob(submission.depot_signoff_signature);

    const formData: FormData = new FormData();
    formData.append('depot_signoff_signature', blob, RestProvider.fileNameRandomiser('depotSignature.png'));
    formData.append('depot_signoff_date', submission.depot_signoff_date);
    formData.append('depot_signoff_name', submission.depot_signoff_name);
    formData.append('status', submission.status);

    const headers = new HttpHeaders({ enctype: 'multipart/form-data'})

    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + '/audit' + '/submissions/' + submission.id + '/', formData, {headers})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  updateCardByID(
    card: CardSet,
    role: number,
    handleErrors = true,
    
  ): Promise<CardSet> {
    const uploadCard: any = {};
    uploadCard.id = card.id;
    uploadCard.text = card.text;
    uploadCard.section = card.section;
    uploadCard.answer = card.answer;
    // delete card.file_groups; doesn't work as it deletes the data from the datasource
    if (card.comments) {
      uploadCard.comments = card.comments;
    }
    if (role == 5) {
      if (card.depot_manager_notes) {
        uploadCard.depot_manager_notes = card.depot_manager_notes;
      }
      
    } else {
      // Cannot send the depot_manager_notes as the API verifies user permissions for Depot Manager only
      delete uploadCard.depot_manager_notes;
    }
    if (card.card_type === 'COMPLIANCE_RATIO') {
      uploadCard.compliance_ratio = card.compliance_ratio;
      uploadCard.number_of_infringements = card.number_of_infringements;
      uploadCard.number_of_working_days = card.number_of_working_days;
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.put<CardSet>(this.apiUrl + '/audit' + '/cards/' + uploadCard.id + '/', JSON.stringify(uploadCard), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  // been a bit lazy I could reduce data down to just answers and comments to maybe increase speed of transfer
  updateTableByID(card: any, handleErrors: boolean = true/*id:number, answer:string, comment:string*/) {
    if (!card.comments) {
      card.comments = '.';
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.put(this.apiUrl + '/audit' + '/tables/' + card.id + '/', JSON.stringify(card), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  // updateSingleAttachment(attachment: any)
  // {
  //   return new Promise((resolve, reject) =>
  //   {
  //     const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
  //     this.http.put(this.apiUrl + '/audit' + '/tables/' + card.id + '/', JSON.stringify(card), {headers})
  //       .subscribe(res =>
  //       {
  //         resolve(res);
  //       }, (err) =>
  //       {
  //         reject(err);
  //       });
  //   });
  // }


  uploadAttachment(fileName: string, file: any, groupId: any, handleErrors: boolean = true) {
    const formData: FormData = new FormData();
    formData.append('file', file, RestProvider.fileNameRandomiser(fileName));
    formData.append('group', groupId);
    formData.append('name', fileName);

    const headers = new HttpHeaders({ enctype: 'multipart/form-data'})
    return new Promise((resolve, reject) => {
      this.http.post(environment.apiUrl + '/audit' + '/files/', formData, {headers})
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            if (!handleErrors || !this.handleCommonErrors(err)) {
              reject(err);
            }
          }
        );
    });
  }

  createFileGroup(description: string, cardId: number, handleErrors: boolean = true) {
    const body = {
      'description': description, // this.groupDescription,
      'card': cardId.toString() // this.data.currentQuestionID
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return new Promise((resolve, reject) => {
      this.http.post<any>(environment.apiUrl + '/audit' + '/filegroups/', JSON.stringify(body), { headers })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (!handleErrors || !this.handleCommonErrors(err)) {
              reject(err);
            }
          }
        );
    });
  }

  updateFileGroupDescription(fileGroup: any, handleErrors: boolean = true) {
    const uploadFileGroup: any = {};
    uploadFileGroup.description = fileGroup.description;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/filegroups/' + fileGroup.id + '/', JSON.stringify(uploadFileGroup), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  archiveFile(fileID: number, handleErrors: boolean = true) {
    const uploadFile: any = {};
    // uploadFile.group = file.group;
    uploadFile.archived = true;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/files/' + fileID + '/', JSON.stringify(uploadFile), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  updateFilesFileGroup(fileID: number, newFileGroupID: number, handleErrors: boolean = true) {
    const uploadFile: any = {};
    // uploadFile.group = file.group;
    uploadFile.group = newFileGroupID;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/files/' + fileID + '/', JSON.stringify(uploadFile), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  updateFile(file: AuditFile, handleErrors: boolean = true) {
    const uploadFile: any = {};
    uploadFile.group = file.group;
    uploadFile.archived = file.archived;
    uploadFile.name = file.name;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/files/' + file.id + '/', JSON.stringify(uploadFile), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  // Helpers

  handleCommonErrors(error) {
    let handled = false;
    if (error.status === 401) {
      handled = true;
      const nav = 'login';
      this.router.navigate([nav], {});
    }
    return handled;
  }

  //TODO review truncation of filename
  static fileNameRandomiser(fileName: string) {
    // https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript
    const fileRouteCharCount = 14; // number of chars taken up by the path to the file folder (2018/06/15/09/)
    const maxFileLength = 100;
    const randomStringLength = 64;
    const regex = /(?:\.([^.]+))?$/;
    const split = regex.exec(fileName);
    let name = fileName.substring(0, split.index);
    const ext = split[0]; // TODO this may be undefined if no extension, do i want to accept files without extensions?

    //truncates the file name to make sure it will fit in the database - 1 to take into account the underscore
    name = name.substring(0, maxFileLength - fileRouteCharCount - ext.length - randomStringLength - 1);
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    name += '_';
    for (let i = 0; i < randomStringLength; i++) {
      name += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    name += ext;

    return name;
  }


  static base64ImageToBlob(dataURI): Blob {

    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  deleteGroup(fileGroup: any, handleErrors: boolean = true) {
    fileGroup.archived = true;

    console.log(fileGroup.archived);
    const uploadFileGroup: any = {};
    uploadFileGroup.archived = fileGroup.archived;

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.patch(this.apiUrl + '/audit' + '/filegroups/' + fileGroup.id + '/', JSON.stringify(uploadFileGroup), { headers })
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          if (!handleErrors || !this.handleCommonErrors(err)) {
            reject(err);
          }
        });
    });
  }

  /* --------------------- Action Items --------------------- */

  getAllActionItems(queryParams): Promise<ActionItem[]> {
    return new Promise((resolve, reject) => {
      const pagination = {page: 1, page_size: 150};
      const params = {...queryParams,...pagination };
      const queryParamResult = this.encodeQuery(params);

      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get<ActionItem[]>(this.apiUrl + '/audit/action_items/?' + queryParamResult, { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  getActionItemById(id: number) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get(this.apiUrl + '/audit/action_items/' + id + '/', { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  saveActionItem(id: number, title: string): Promise<ActionItem> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const body = {
      card: id,
      title,
      status: 0
    };

    return new Promise((resolve, reject) => {
      this.http.post<ActionItem>(this.apiUrl + '/audit/action_items/', JSON.stringify(body), { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  updateActionItemById(cardId: number, title: string, id: number, status: number): Promise<ActionItem> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    const body = {
      card: cardId,
      title,
      status,
    }

    return new Promise((resolve, reject) => {
      this.http.put<ActionItem>(this.apiUrl + '/audit/action_items/' + id + '/', JSON.stringify(body), { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  deleteActionItemById(id: number) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.delete(this.apiUrl + '/audit/action_items/' + id + '/', { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  /* --------------------- Action Items - Comments --------------------- */

  getListActionItemComments(action_item_id: number) {
    return new Promise<ActionItemComment[]>((resolve, reject) => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
      this.http.get<ActionItemComment[]>(this.apiUrl + '/audit/action_items/' + action_item_id + '/comments/', { headers }).subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  addActionItemComment(action_item_id: number, message: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const body = { message };
    return new Promise<ActionItemComment>((resolve, reject) => {
      this.http.post<ActionItemComment>(this.apiUrl + '/audit/action_items/' + action_item_id + '/comments/', body, { headers })
        .subscribe((res) => resolve(res), (err) => reject(err));
    })
  }

  addActionItemCommentFiles(action_item_id: number, message: string, files: File[]) {
    const headers = new HttpHeaders({  "Accept": "application/json" });
    // const body = { message };
    const body = new FormData();
    body.append('message', message)
    files.forEach(file => body.append('comment_file', file, file.name));


    return new Promise<ActionItemComment>((resolve, reject) => {
      this.http.post<ActionItemComment>(this.apiUrl + '/audit/action_items/' + action_item_id + '/comments/', body, { headers })
        .subscribe((res) => resolve(res), (err) => reject(err));
    })
  }
}
