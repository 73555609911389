import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type15Datasource} from './type-15.datasource';

@Component({
  selector: 'app-type-15',
  templateUrl: './type-15.component.html',
  styleUrls: ['./type-15.component.css', '../table-common.css']
})
export class Type15Component implements OnInit, AfterViewInit {

  displayedColumns = [ 'driver', 'defects', 'domesticHours', 'optOut', 'comments'];

  dataSource: Type15Datasource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type15Datasource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDefects(index: number, defects: string) {
    this.dataSource.updateDefects(index, defects);
  }

  updateDomesticHours(index: number, domesticHours: string) {
    this.dataSource.updateDomesticHours(index, domesticHours);
  }

  updateOptOut(index: number, optOut: string) {
    this.dataSource.updateOptOut(index, optOut);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }
}
