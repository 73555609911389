export const environment = {
  envName: 'prod',
  production: true,
  imagePrefix: '',
  apiUrl: 'https://api-dot-datalive-auditapp-production.appspot.com',
  ssoAuthUrl: 'https://fcr.datalive.com/oauth2/', // Local
  authRedirectLoginUrl: 'https://auth.datalive.com/',
  appReference: 'AUDIT',
  developmentAccessToken: null
};
