import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType1} from '../../_models';
import {Data} from '../../_providers';
import {BehaviorSubject, Observable} from 'rxjs';
import {TimeInputMethods} from '../../_helpers';


export class Type1DataSource implements DataSource<TableType1> {
  // {
  // date:'14/8/2017',
  // driver:'Wayne Emlyn Nightingale',
  // ccStart:'5:28 am',
  // ddcStart: '7:28 am',
  // ccFinish: '6:57 pm',
  // ddcFinish:'6:57 pm',
  // comments:''
  // },

  weekdays: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType1[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 28) {
      for (let i = this.tableData.table_data.length; i < 28; i++) {
        const tempObj: any = {};
        tempObj.date = '';
        tempObj.driver = '';
        tempObj.ccStart = '';
        tempObj.ddcStart = '';
        tempObj.ccFinish = '';
        tempObj.ddcFinish = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  replaceData(data) {

    // TODO i dislike this it may be needed to bring in momentjs
    for (let i = 0; i < data.length; i++) {
      // console.log(data[i]);
      // console.log(data[i].date);
      if (data[i].date != null) {
        const temp = data[i].date.split('/');
        if (temp.length === 3) {
          // -1 on the month as it is 0 based
          data[i].date = new Date(parseInt(temp[2], 10), parseInt(temp[1], 10) - 1, parseInt(temp[0], 10)).toISOString();
        }
      }

      if (data[i].ccStart != null) {
        data[i].ccStart = TimeInputMethods.timeToISOString(data[i].ccStart);
      }
      if (data[i].ddcStart != null) {
        data[i].ddcStart = TimeInputMethods.timeToISOString(data[i].ddcStart);
      }
      if (data[i].ccFinish != null) {
        data[i].ccFinish = TimeInputMethods.timeToISOString(data[i].ccFinish);
      }
      if (data[i].ddcFinish != null) {
        data[i].ddcFinish = TimeInputMethods.timeToISOString(data[i].ddcFinish);
      }
    }
    this.tableData.table_data = data;

    this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  dataLength() {
    return this.tableData.table_data.length; // TODO maybe needs to change
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType1[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType1[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.date = new Date(this.tableData.table_data[i].date);
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.day = this.weekdays[tempObj.date.getDay()];

      tempObj.ccStart = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].ccStart);
      tempObj.ddcStart = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].ddcStart);
      tempObj.ccFinish = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].ccFinish);
      tempObj.ddcFinish = TimeInputMethods.isoStringToTimeInput(this.tableData.table_data[i].ddcFinish);

      tempObj.amVariance = TimeInputMethods.timeDifference(tempObj.ccStart, tempObj.ddcStart);
      tempObj.pmVariance = TimeInputMethods.timeDifference(tempObj.ccFinish, tempObj.ddcFinish);
      tempObj.totalVariance = TimeInputMethods.timeSummation(tempObj.amVariance, tempObj.pmVariance);


      tempObj.comments = this.tableData.table_data[i].comments;
      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }

  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    // this.data.replaceTableById(this.tableData,this.tableData.id);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDate(index: number, newDate: Date) {
    this.tableData.table_data[index].date = newDate.toISOString();
    // this.data.replaceTableById(this.tableData,this.tableData.id);

    this.test2.apply(this);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateCcStart(index: number, newTime: string) {
    // this.tableData.table_data[index].ccStart = MaterialTimeConversions.materialTimeToDate(newMaterialTime).getTime();
    this.tableData.table_data[index].ccStart = TimeInputMethods.timeToISOString(newTime);
    // this.data.replaceTableById(this.tableData,this.tableData.id);
    this.test.apply(this);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);

  }

  updateDdcStart(index: number, newTime: string) {
    this.tableData.table_data[index].ddcStart = TimeInputMethods.timeToISOString(newTime);
    // this.data.replaceTableById(this.tableData,this.tableData.id);
    this.test.apply(this);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);

  }

  updateCcFinish(index: number, newTime: string) {
    this.tableData.table_data[index].ccFinish = TimeInputMethods.timeToISOString(newTime);
    // this.data.replaceTableById(this.tableData,this.tableData.id);
    this.test.apply(this);
    // this.debounce.apply(this, [this.loadData(), 3000, false]);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);

  }

  updateDdcFinish(index: number, newTime: string) {
    this.tableData.table_data[index].ddcFinish = TimeInputMethods.timeToISOString(newTime);
    // this.data.replaceTableById(this.tableData,this.tableData.id);
    const context = this;
    this.test.apply(context);
    // this.debounce.apply(this, [this.loadData(), 50, false]);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);

  }


  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.data.replaceTableById(this.tableData.table_data,this.tableData.table_data.id);
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  debounceLoad(){
    this.test.apply(this);
  }


  test = this.debounce(this.loadData, 5000, false);
  test2 = this.debounce(this.loadData, 10, false);

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  debounce(func, wait, immediate) {
    let timeout;

    // This is the function that is actually executed when
    // the DOM event is triggered.
    return function executedFunction() {
      // Store the context of this and any
      // parameters passed to executedFunction
      const context = this;
      // const args = arguments;

      // The function to be called after
      // the debounce time has elapsed
      const later = function () {
        // null timeout to indicate the debounce ended
        timeout = null;
        console.log('later ' + wait);
        // Call function now if you did not on the leading end
        if (!immediate) {
          func.apply(context);
        }
      };

      // Determine if you should call the function
      // on the leading or trail end
      const callNow = immediate && !timeout;

      // This will reset the waiting every function execution.
      // This is the step that prevents the function from
      // being executed because it will never reach the
      // inside of the previous setTimeout
      clearTimeout(timeout);

      // Restart the debounce waiting period.
      // setTimeout returns a truthy value (it differs in web vs node)
      timeout = setTimeout(later, wait);

      // Call immediately if you're dong a leading
      // end execution
      if (callNow) {
        func.apply(context);
      }
    };
  }
}
