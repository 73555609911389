import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { RestProvider } from '../_providers';

import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { IUser, IUserObject, UserInfoProfileModel } from '../_models';
import { promise } from 'protractor';
import { MatSnackBar } from '@angular/material/snack-bar';



@Injectable()
export class AuthenticationService {
  public static COOKIE_TOKEN_NAME: string = 'access-token';
  public static USER_KEY: string = 'currentUser';

  apiUrl = environment.apiUrl;
  token: string = null;
  user: IUserObject = null;

  userDetail$ = new BehaviorSubject<IUserObject>(this.getUserFromSessionStorage());
  constructor(private http: HttpClient,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    private rest: RestProvider) {
  }


  getUserFromSessionStorage(): null | IUserObject {
    let data: IUserObject = JSON.parse(sessionStorage.getItem(AuthenticationService.USER_KEY));
    let user: null | IUserObject = null;
    if (data) { user = data }
    return user;
  }

  async getAuthCookie() {
    const authCookie = environment.developmentAccessToken ? environment.developmentAccessToken : this.cookieService.get(AuthenticationService.COOKIE_TOKEN_NAME);
    return authCookie;
  }
  async removeAuthCookie() {
    return this.cookieService.delete(AuthenticationService.COOKIE_TOKEN_NAME);
  }

  
  login(email: string, password: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    // return this.http.post<any>('/api/authenticate', { username: username, password: password })
    return this.http.post<any>(this.apiUrl + '/api-token-auth/', { email: email, password: password }, { headers }).pipe(
      map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.userDetail$.next(user);
          sessionStorage.setItem(AuthenticationService.USER_KEY, JSON.stringify(user));
        }

        return user;
      }));
  }

  /**
   * ssoFlow Login method
   * @returns 
   */
  public async ssoLogin() {

    return new Promise<boolean>(async (resolve, reject) => {
      const hasAccessToken = await this.checkToken();
      if (!hasAccessToken) {
          this.logout();
          window.location.replace(environment.ssoAuthUrl+ 'login');
          setTimeout(() => {
            console.log("Waiting to redirect...");
            resolve(false);
          }, 4000);
         
      }

      console.log('Logged in SSO', hasAccessToken);

      resolve(true);
    });
    // this.router.navigate(['../dashboard']);


    // this.checkToken().then((result) => {
    //   if (!result) {
    //     this.logout();
    //     window.location.replace(environment.ssoAuthUrl);
    //     return false;
    //   } else {
    //     console.log('Logged in SSO :)', result);
    //     // const redirectUrl = this.returnUrl ? this.returnUrl : '/audits/';
    //     // this.router.navigate([redirectUrl]);
    //   }

    // });
}




 private async checkToken(): Promise<boolean> {
    // return this.storage.get(TOKEN_KEY).then(token => {
    const token = await this.getAuthCookie();
    let user = await this.getUserFromSessionStorage();

    if (user) {
        await sessionStorage.removeItem(AuthenticationService.USER_KEY);
        user = null;
    }

    // if (token) {
    //     // this.restProvider.jwt = token;
    // } 

    return new Promise<boolean>(async (resolve, reject) => {
        if (token) {
          this.rest.token = token;
          if (user) {
              // const decoded = jwtHelper.decodeToken(token);
              const isExpired = false

              if (!isExpired) {
                // this.token = token;
                // this.restProvider.jwt = token;
                // AuthService.accessToken = this.
                sessionStorage.setItem(AuthenticationService.USER_KEY, JSON.stringify(user));
                this.cookieService.set(AuthenticationService.COOKIE_TOKEN_NAME, token);
                // Store user details and jwt token in local storage to keep user logged in between page refreshes
                this.userDetail$.next(user);

                
                // console.log(user.extra_vars.permissions)
                // this.permissionService.LoadRole(user.extra_vars.permissions);
                resolve(true);
              } else {
                this.userDetail$.next(null);
                resolve(false);
              }

          } else {

              // TODO use datalive get
              try {
                // const user = await this.rest.getUserInfo(token);
                const user = await this.rest.getCurrentUser(token);
                if (user && user['detail'] == "No such user") {
                  console.log('User not permitted to login')
                  const alert = {
                    status: 'Not Permitted',
                    message: 'User session expired or your user does not have permission',
                  };
                  this.snackBar.open(alert.message, '', {
                    duration: 4000,
                    verticalPosition: 'bottom'
                  });

                  this.logout(true);

                  resolve(false);
                }

                // this.rest.getCurrentUser(token).then((user) => {
                //   // const user = await this.restProvider.getUserInfo()
                //   // TODO - clean this up
                //   this.token = token;
                //   this.rest.token = token;
                //   this.cookieService.set(AuthenticationService.COOKIE_TOKEN_NAME, token);

                //   this.user = user as IUser;
                //   this.userDetail$.next(user);
                //   sessionStorage.set(AuthenticationService.USER_KEY, user);
  

                //   resolve(true)

                // }).catch((e) => {
                //   console.log('Error getting getUserInfo: ', e);
                //   reject(false)
                // });
    

                // // const user = await this.restProvider.getUserInfo()
                // TODO - clean this up
                this.token = token;
                this.rest.token = token;
                this.cookieService.set(AuthenticationService.COOKIE_TOKEN_NAME, token);

                this.user = user as IUserObject;
                this.userDetail$.next(user);
                sessionStorage.setItem(AuthenticationService.USER_KEY, JSON.stringify(user));
                // TODO set new permissions
                // this.permissionService.LoadRole(user.extra_vars.permissions);
                // TODO   
                // await this.getUsersCustomer();

                resolve(true)
                
              } catch (error) {
                console.log('Error getting getUserInfo: ', error);
                reject(false)
              }
              

          }

        } else {
          resolve(false)
        }
    });


}


  logout(notAutorised: boolean = false) {
    // remove user from local storage to log user out
    sessionStorage.removeItem(AuthenticationService.USER_KEY);
    this.userDetail$.next(null);
    this.removeAuthCookie();
    const redirectQueryParam = notAutorised ? 'authStatus=notauthorised' : 'authStatus=loggedout'
    location.href = environment.ssoAuthUrl + 'logout/?redirect_uri=/login?' + redirectQueryParam;
  }


}
