import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Data } from '../../_providers';
import { Type4DataSource } from './type-4.datasource';


@Component({
  selector: 'app-type-4',
  templateUrl: './type-4.component.html',
  styleUrls: ['./type-4.component.css', '../table-common.css']
})
export class Type4Component implements OnInit, OnDestroy {
  displayedColumns = ['onRegister', 'driver', 'licenceOK', 'date', 'ddcExpiryCopy', 'dqcExpiryCopy', 'comments'];

  dataSource: Type4DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.dataSource = new Type4DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateOnRegister(index: number, onRegister: string) {
    this.dataSource.updateOnRegister(index, onRegister);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
    // this.loadData();
  }

  updateLicenceOk(index: number, licenceOK: string) {
    this.dataSource.updateLicenceOk(index, licenceOK);
  }

  updateDate(index: number, newDate: Date) {
    this.dataSource.updateDate(index, newDate);
    // this.loadData();
  }

  updateDdcExpiryCopy(index: number, ddcExpiryCopy: string) {
    this.dataSource.updateDdcExpiryCopy(index, ddcExpiryCopy);
  }

  updateDqcExpiryCopy(index: number, dqcExpiryCopy: string) {
    this.dataSource.updateDqcExpiryCopy(index, dqcExpiryCopy);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
