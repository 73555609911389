// import {Injectable} from '@angular/core';
// import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
// import { from, Observable, throwError } from 'rxjs';
// import { map, catchError, switchMap } from 'rxjs/operators';
// import { AuthenticationService } from '../_services';

// @Injectable()
// export class JwtInterceptor implements HttpInterceptor {
//     constructor(
//       private authService: AuthenticationService
//     ) {
//     }
//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//     return from(this.authService.getAuthCookie())
//     .pipe(
//         switchMap(
//             res => {
//                 let authToken = res;
//                 const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
//                 if (currentUser && authToken) {
//                   console.log('interceptor user? ', currentUser != null);
//                   request = request.clone({
//                     setHeaders: {
//                       Authorization: `Bearer ${currentUser.token}`
//                     }
//                   });
//                 } else if (!authToken) {
//                   const headersNoAuth = new HttpHeaders({
//                       'Content-Type': 'application/json; charset=utf-8'
//                   });
//                   const authReq = request.clone({ headers: headersNoAuth });
//                   return next.handle(authReq).pipe(
//                       map((event: HttpEvent<any>) => {
//                           // Request Good.
//                           return event;
//                       }),
//                       catchError((error: HttpErrorResponse) => {
//                           // Request Bad.
//                           // this.httpError(error, authToken);
//                           return throwError(error);
//                       })
//                   );
//               } else {
//                   const headers = new HttpHeaders({
//                       Authorization: 'Bearer ' + authToken,
//                       'Content-Type': 'application/json; charset=utf-8'
//                   });
//                   const authReq = request.clone({ headers });
//                   return next.handle(authReq).pipe(
//                       map((event: HttpEvent<any>) => {
//                           // Request Good.
//                           return event;
//                       }),
//                       catchError((error: HttpErrorResponse) => {
//                           // Request Bad.
//                           // this.httpError(error, authToken);
//                           return throwError(error);
//                       })
//                   );
//               }
//             }))
    

//   }

  // private httpError(error: any, authToken: any) {
  //   // // console.log('HTTP Error.', error, authToken);
  //   if ((error.status !== undefined && error.status === 400) &&
  //       (error.url !== undefined && error.url === environment.url + '/authenticate/')) {
  //       // console.log('Username or password was incorrect');
  //   } else if (error.status === 0 && error.statusText === 'Unknown Error') {
  //       // console.log('App data sync didnt complete');
  //   } else {
  //       if (error.status === 401) {
  //           this.global.retries++;
  //           if (this.global.retries >= 2) {
  //               this.authService.logout();
  //               this.global.retries = 0;
  //           } else {
  //               // console.log('401 Error');
  //           }
  //       }
  //   }
  // }

// }


import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from '../_services';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material';



@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    jwt: string;

    constructor(
        private injector: Injector,
        private authService: AuthenticationService,
        private snackBar: MatSnackBar
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.authService.getAuthCookie())
            .pipe(
                switchMap(
                    res => {

                        let authToken = res;
                        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
         

                        if ((authToken == null || authToken === '') && currentUser) {
                            console.log('Token NULL or empty.');
            
                            return next.handle(req).pipe(
                                map((event: HttpEvent<any>) => {
                                    // Request Good.
                                    return event;
                                }),
                                catchError((error: HttpErrorResponse) => {
                                    // Request Bad.
                                    // this.httpError(error, authToken);
                                    switch (error.status) {
                                        case 401:
                                        case 403:
                                          console.log('401 or 403', error);
                                          // Logout
                                          this.authService.logout(true);
                                          break;
                                      
                                        default:
                                          console.log(error);
                                          break;
                                      }
                       
                                    return throwError(error);
                                })
                            );
                        } else if (!authToken) {
                            const headersNoAuth = new HttpHeaders({
                                'Content-Type': 'application/json; charset=utf-8'
                            });
                            const authReq = req.clone({ headers: headersNoAuth });
                            return next.handle(authReq).pipe(
                                map((event: HttpEvent<any>) => {
                                    // Request Good.
                                    return event;
                                }),
                                catchError((error: HttpErrorResponse) => {
                                    // Request Bad.
                                    switch (error.status) {
                                        case 401:
                                        case 403:
                                          console.log('401 or 403', error);
                                          // Logout
                                          this.authService.logout(true);
                                          break;
                                      
                                        default:
                                          console.log(error);
                                          break;
                                      }
                         
                                    return throwError(error);
                                })
                            );
                        } else {

                            let headers;
                            if (req.headers.has('Content-Type')) {
                                const contentType = req.headers.get('Content-Type');
                                headers = new HttpHeaders({
                                    Authorization: 'Bearer ' + authToken,
                                    'Content-Type': contentType
                                });
                            } else {
                                headers = new HttpHeaders({
                                    Authorization: 'Bearer ' + authToken
                                });
                            }
                           
                            const authReq = req.clone({ headers });
                            return next.handle(authReq).pipe(
                                map((event: HttpEvent<any>) => {
                                    // Request Good.
                                    return event;
                                }),
                                catchError((error: HttpErrorResponse) => {
                                    // Request Bad.

                                    switch (error.status) {
                                        case 401:
                                            console.log('401', error);
                                          // Logout
                                          this.authService.logout(true);
                                          break;
                                        case 403:
                                          console.log('403', error);
                                          // Logout
                                          const alert = {
                                            status: 'Not Permitted',
                                            message: 'Your user does not have permission for this API call',
                                          };
                                          this.snackBar.open(alert.message, '', {
                                            duration: 4000,
                                            verticalPosition: 'bottom'
                                          });
                                           // Logout
                                           this.authService.logout(true);
                                          break;
                                      
                                        default:
                                          console.log(error);
                                          break;
                                      }

                                    return throwError(error);
                                })
                            );
                        }
                    }
                )
            )



    }
    // private httpError(error: any, authToken: any) {
    //     // // console.log('HTTP Error.', error, authToken);
    //     if ((error.status !== undefined && error.status === 400) &&
    //         (error.url !== undefined && error.url === environment.url + '/authenticate/')) {
    //         // console.log('Username or password was incorrect');
    //     } else if (error.status === 0 && error.statusText === 'Unknown Error') {
    //         // console.log('App data sync didnt complete');
    //     } else {
    //         if (error.status === 401) {
    //             this.global.retries++;
    //             if (this.global.retries >= 2) {
    //                 this.authService.logout();
    //                 this.global.retries = 0;
    //             } else {
    //                 // console.log('401 Error');
    //             }
    //         }
    //     }
    // }
}
