import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class QuestionListService {

  // Observable string sources
  private questionID = new Subject<number>();

  // Observable string streams
  questionAnnounced$ = this.questionID.asObservable();

  // Service message commands
  announceQuestion(id: number) {
    this.questionID.next(id);
  }


}
