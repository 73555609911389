import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Data} from '../../_providers';
import {BehaviorSubject, Observable} from 'rxjs';
import {TableType15} from '../../_models/tables/type-15';


export class Type15Datasource implements DataSource<TableType15> {
  // {
  // date:'',
  // driver: '',
  // infringementDate: '',
  // infringement: '',
  // signedLetterfoundInFile:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType15[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 5) {
      for (let i = this.tableData.table_data.length; i < 5; i++) {
        const tempObj: any = {};
        tempObj.driver = '';
        tempObj.defects = '';
        tempObj.domesticHours = '';
        tempObj.optOut = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType15[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType15[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.defects = this.tableData.table_data[i].defects;
      tempObj.domesticHours = this.tableData.table_data[i].domesticHours;
      tempObj.optOut = this.tableData.table_data[i].optOut;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDefects(index: number, defects: string) {
    this.tableData.table_data[index].defects = defects;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDomesticHours(index: number, domesticHours: string) {
    this.tableData.table_data[index].domesticHours = domesticHours;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateOptOut(index: number, optOut: string) {
    this.tableData.table_data[index].optOut = optOut;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    this.data.pushTableUpdate(this.tableData.id);
  }


}
