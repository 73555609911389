import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule, MAT_DATE_LOCALE
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignaturePadModule } from 'angular2-signaturepad';
import { environment } from '../environments/environment';
import { ActionItemsComponent } from './action-items/action-items.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuditListComponent } from './audit-list/audit-list.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { CreateActionItemFormComponent } from './create-action-item-form/create-action-item-form.component';
import { CsvDialogComponent } from './dialogs/csv-dialog/csv-dialog.component';
import { DeleteFileGroupComponent } from './dialogs/delete-file-group/delete-file-group.component';
import { FileGroupEditorComponent } from './dialogs/file-group-editor/file-group-editor.component';
import { FullScreenImageComponent } from './dialogs/full-screen-image/full-screen-image.component';
import { LoginFailedComponent } from './dialogs/login-failed/login-failed.component';
import { SignatureCaptureComponent } from './dialogs/signature-capture/signature-capture.component';
import { SplitFileGroupComponent } from './dialogs/split-file-group/split-file-group.component';
import { TableViewerComponent } from './dialogs/table-viewer/table-viewer.component';
import { UnansweredQuestionsDialogComponent } from './dialogs/unanswered-questions-dialog/unanswered-questions-dialog.component';
import { FileGroupViewComponent } from './file-group-view/file-group-view.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthGuardServiceGuard } from './guards/auth-guard-service.guard';
import { QuestionListGuardGuard } from './guards/question-list-guard.guard';
import { SectionListGuardGuard } from './guards/section-list-guard.guard';
import { SubmissionListGuardGuard } from './guards/submission-list-guard.guard';
import { LoginComponent } from './login/login.component';
import { ModalComponent } from './modal/modal.component';
import { NewFileUploadComponent } from './new-file-upload/new-file-upload.component';
import { QuestionListComponent } from './question-list/question-list.component';
import { QuestionComponent } from './question/question.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SectionListComponent } from './section-list/section-list.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { SubmissionListComponent } from './submission-list/submission-list.component';
import { TableCardComponent } from './table-card/table-card.component';
import { TableDataDrivenComponent } from './table/table-data-driven/table-data-driven.component';
import { Type1Component } from './table/type-1/type-1.component';
import { Type10Component } from './table/type-10/type-10.component';
import { Type11Component } from './table/type-11/type-11.component';
import { Type12Component } from './table/type-12/type-12.component';
import { Type13Component } from './table/type-13/type-13.component';
import { Type14Component } from './table/type-14/type-14.component';
import { Type15Component } from './table/type-15/type-15.component';
import { Type2Component } from './table/type-2/type-2.component';
import { Type3Component } from './table/type-3/type-3.component';
import { Type4Component } from './table/type-4/type-4.component';
import { Type5Component } from './table/type-5/type-5.component';
import { Type6Component } from './table/type-6/type-6.component';
import { Type7Component } from './table/type-7/type-7.component';
import { Type8Component } from './table/type-8/type-8.component';
import { Type9Component } from './table/type-9/type-9.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { MessageDialogComponent } from './_dialogs/message-dialog/message-dialog.component';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
// used to create fake backend
import { JwtInterceptor } from './_helpers';
import { Data, GlobalSettings, RestProvider } from './_providers';
import { AlertService, AuthenticationService } from './_services';
import { ExpandableActionItemsComponent } from './expandable-action-items/expandable-action-items.component';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    SectionListComponent,
    QuestionListComponent,
    QuestionComponent,
    FileUploadComponent,
    BreadcrumbComponent,
    TableCardComponent,
    Type1Component,
    Type2Component,
    Type3Component,
    Type4Component,
    Type5Component,
    Type6Component,
    Type7Component,
    Type8Component,
    Type9Component,
    Type10Component,
    Type11Component,
    Type12Component,
    Type13Component,
    Type14Component,
    Type15Component,
    NewFileUploadComponent,
    SubmissionListComponent,
    AuditListComponent,
    LoginComponent,
    SignaturePadComponent,
    UnansweredQuestionsDialogComponent,
    FullScreenImageComponent,
    SplitFileGroupComponent,
    SignatureCaptureComponent,
    MessageDialogComponent,
    FileGroupEditorComponent,
    FileGroupViewComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TableDataDrivenComponent,
    TableViewerComponent,
    ModalComponent,
    LoginFailedComponent,
    SpinnerLoadingComponent,
    ToolbarComponent,
    CsvDialogComponent,
    DeleteFileGroupComponent,

    UserDropdownComponent,
    CommentModalComponent,
    ActionItemsComponent,
    CreateActionItemFormComponent,
    ExpandableActionItemsComponent,

  ],
  imports: [
    CdkTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTableModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    BrowserAnimationsModule,
    CdkTableModule,
    SignaturePadModule,
    MatBadgeModule,
    OverlayModule,
  ],
  exports: [CdkTableModule, MatTableModule, BreadcrumbComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    Data,
    GlobalSettings,
    RestProvider,
    AuthGuard,
    AlertService,
    AuthenticationService,
    AuthGuardServiceGuard,
    SubmissionListGuardGuard,
    SectionListGuardGuard,
    QuestionListGuardGuard,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    DatePipe

    // provider used to create fake backend
  ],
  // tslint:disable-next-line:max-line-length
  entryComponents: [DeleteFileGroupComponent, CsvDialogComponent, SpinnerLoadingComponent, LoginFailedComponent, UnansweredQuestionsDialogComponent, SplitFileGroupComponent, FullScreenImageComponent, SignatureCaptureComponent, MessageDialogComponent, FileGroupEditorComponent, TableViewerComponent, ModalComponent, CommentModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
// platformBrowserDynamic().bootstrapModule(AppModule);
