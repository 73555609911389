import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Data} from '../_providers';

@Injectable()
export class SectionListGuardGuard implements CanActivate {
  constructor(public data: Data, private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.data.currentSubmission) {
      return true;
    } else {
      this.router.navigate(['/audits/submission']);
      return false;
    }
  }
}
