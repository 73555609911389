import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import {SignaturePad} from 'angular2-signaturepad/signature-pad';
import { SignaturePad } from 'angular2-signaturepad';

import { Data } from '../../_providers';

@Component({
  selector: 'app-signature-capture',
  templateUrl: './signature-capture.component.html',
  styleUrls: ['./signature-capture.component.css']
})
export class SignatureCaptureComponent implements OnInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  auditor: boolean;
  nameOfSigner: string;

  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'maxWidth': 2.5,
    // TODO get height and width of screen then set values
    'canvasWidth': window.innerWidth * 0.5,
    'canvasHeight': window.innerHeight * 0.35
  };
  showSignatureHint = false;
  submitAttempted = false;

  constructor(public dialogRef: MatDialogRef<SignatureCaptureComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private data: Data) {
    this.auditor = dialogData.auditor;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.set('maxWidth', 2.5);
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }


  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    // this.signature.emit(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }


  clearSignature() {
    this.signaturePad.clear();
  }

  submitSig() {
    if (this.signaturePad.isEmpty()) {
      this.showSignatureHint = true;
    } else {
      this.data.addSignature(this.signaturePad.toDataURL(), this.nameOfSigner, this.auditor).then((result) => {
        this.dialogRef.close();
      }, (err) => {
        console.log(err);
        alert('Failed to save signature please try again');
        // TODO Should (maybe) display error to user in a better way
      });
    }
  }
  close() {
    this.dialogRef.close();
  }
}

