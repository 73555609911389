import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Data, RestProvider} from '../_providers';


@Injectable()
export class AuthGuardServiceGuard implements CanActivate {
  constructor(private data: Data, private router: Router, private rest: RestProvider) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if(this.rest.loggedIn())
    // {
    //   return true;
    // }else
    // {
    //   this.router.navigate(['/login']);
    //   return false;
    // }
    return true;
  }


}
