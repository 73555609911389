import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Data } from '../../_providers';
import { Type13DataSource } from './type-13.datasource';

@Component({
  selector: 'app-type-13',
  templateUrl: './type-13.component.html',
  styleUrls: ['./type-13.component.css', '../table-common.css']
})
export class Type13Component implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns = ['date', 'driver', 'infringementDate', 'infringement', 'signedLetterFoundInFile'];

  dataSource: Type13DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new Type13DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDate(index: number, date: string) {
    this.dataSource.updateDate(index, date);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateInfringementDate(index: number, infringementDate: string) {
    this.dataSource.updateInfringementDate(index, infringementDate);
  }

  updateInfringement(index: number, infringement: string) {
    this.dataSource.updateInfringement(index, infringement);
  }

  updateSignedLetterFoundInFile(index: number, signedLetterFoundInFile: string) {
    this.dataSource.updateSignedLetterFoundInFile(index, signedLetterFoundInFile);
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
