import {Component, Input, OnInit} from '@angular/core';
import {Data} from '../../_providers';

@Component({
  selector: 'app-type-14',
  templateUrl: './type-14.component.html',
  styleUrls: ['./type-14.component.css', '../table-common.css']
})
export class Type14Component implements OnInit {

  @Input() inputData: any; // card_Set
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  tableData: any; // table


  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.tableData = this.data.getTableById(this.inputData.table); /// TODO maybe data validation
    if (!('daysChecked' in this.tableData.table_data)) {
      this.tableData.table_data.daysChecked = '';
    }
    if (!('daysOk' in this.tableData.table_data)) {
      this.tableData.table_data.daysOk = '';
    }
    if (!('compliance' in this.tableData.table_data)) {
      this.tableData.table_data.compliance = '';
    }
  }

  update() {
    this.data.pushTableUpdate(this.tableData.id);
  }

}
