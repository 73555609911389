import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatExpansionPanel, MatSnackBar } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnansweredQuestionsDialogComponent } from '../dialogs/unanswered-questions-dialog/unanswered-questions-dialog.component';
import { IUserObject, Submission, SubmissionReduced } from '../_models';
import { Data, GlobalSettings, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';

// import {MatDialog} from '@angular/material/typings/dialog';

@Component({
  selector: 'app-submission-list',
  templateUrl: './submission-list.component.html',
  styleUrls: ['./submission-list.component.css'],
  encapsulation: ViewEncapsulation.None // Use to disable CSS Encapsulation for this component
})
export class SubmissionListComponent implements OnInit, OnDestroy {

  _destroy$: Subject<void> = new Subject();
  public expandedHeight: any = '60px';
  public collapsedHeight: any = '60px';
  myUrl: number;
  public displayClass = 'top';
  userRole = 0;
  backToPath: '' | 'sections' | 'audit list' | 'submission' | 'home' = '';
  submissionKey = '';
  userDetail: IUserObject | null = null;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    public data: Data,
    private globalSettings: GlobalSettings,
    private restProvider: RestProvider,
    public dialog: MatDialog,
    private _authenticationService: AuthenticationService,
    public snackBar: MatSnackBar
  ) {

    this.globalSettings.isFullScreen$.pipe(takeUntil(this._destroy$))
      .subscribe(() => { this.navigate(); });

    // should come up with a not so low level method to tell component position
    router.events.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();
      }
    });
    // this code only runs when it is initialised so we can determine the components position from the url used to load it
    this.myUrl = (router.url.match(/\//g) || []).length;
  }


  navigate() {
    const _url = this.router.url;

    if (_url.includes('newfiles')) {
      this.backToPath = 'audit list';
    } else if (_url.includes('files')) {
      this.backToPath = 'sections';
    } else if (_url.includes('questions')) {
      this.backToPath = 'audit list';
    } else if (_url.includes('sections')) {
      this.backToPath = 'submission';
    } else if (_url.includes('submission')) {
      this.backToPath = 'home';
    } else {
      this.backToPath = '';
    }

    if (this.globalSettings.isFullScreen.value) {
      if ((_url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'full-top';
      } else {
        this.displayClass = 'gone';
      }
    } else {
      if ((_url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'top';
      } else if ((_url.match(/\//g) || []).length === this.myUrl + 1) {
        this.displayClass = 'bottom';
      } else {
        this.displayClass = 'gone';
      }
    }
  }

  ngOnInit() {
    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
    this.userDetail = user;
  }

  goToBack(backTo: '' | 'sections' | 'audit list' | 'submission' | 'home') {
    switch (backTo) {
      case 'sections':
        this.router.navigate(['sections/questions'], { relativeTo: this.route });
        break;
      case 'audit list':
        this.router.navigate(['sections'], { relativeTo: this.route });
        break;
      case 'submission':
        this.router.navigate(['.'], { relativeTo: this.route });
        break;
      case 'home':
        this.router.navigate(['..'], { relativeTo: this.route });
        break;
    }

    if (backTo === 'home') {
      const keys = ['annual', 'apr', 'aug', 'b1', 'b2', 'dec', 'feb', 'jan', 'jul', 'jun', 'mar', 'may', 'nov', 'oct', 'q1', 'q2', 'q3', 'q4', 'sep'];

      let totalCountOfResponse = 0;

      for (let result of this.data.auditList) {
        for (let key of keys) {
          if (result['submission_' + key].action_items_needing_review !== null) {
            totalCountOfResponse += result['submission_' + key].action_items_needing_review;
          }
        }
      }

      this.data.totalCountOfResponse = totalCountOfResponse;
    }
  }

  onTap(submission, matExpansionPanel: MatExpansionPanel, event: MouseEvent): void {
    // if(!submission.action_items_needing_review && this.userDetail && [2].includes(this.userDetail.role)){
    //   return
    // }
    // this.submissionKey = submissionKey;
    if (submission.na === false) {
      event.stopPropagation();
      matExpansionPanel.close();
      this.data.switchSubmission(submission, this.userRole);
      const nav = 'sections';
      this.router.navigate([nav], { relativeTo: this.route });
    }
  }

  auditChange(auditId: number): void {

    this.data.switchAudit(auditId);
    if (this.submissionKey && this.data.currentAudit) {
      // TODO - set the first submission in the list
      if (this.data.currentAudit.submissions.length > 0) {
        this.data.switchSubmission(this.data.currentAudit.submissions[0], this.userRole);
      }
       // TODO - 
      if (this.data.currentSection) {
        this.router.navigate(['sections'], { relativeTo: this.route });
      }
    }
  }


  submitScore(submissionKey: string, event: MouseEvent) {
    event.stopPropagation(); // Stops the expander collapsing and loading the submision sections
    const submission = this.data.currentAudit[submissionKey];

    Data.submitSubmission(submission);
    this.restProvider.updateSubmissionByIDForDashBoard(submission); // TODO do i want to do it here

  }


  private padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  private formatDate(date) {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-');
  }
  getSubmissionDays(data) {
    let date1 = new Date(data)
    let date2 = new Date()
    let difference = date1.getTime() - date2.getTime();
    let days = Math.floor(difference / 86400000)
    if (days > 0) {
      return `${Math.floor(difference / 86400000)} days`
    } else if (days === 0) {
      return "Today"
    } else {
      return "Expired"
    }
  }
  async setDeadlineOnSubmission(currentSubmission: SubmissionReduced, event) {
    event.stopPropagation();

    const submission = currentSubmission;
    let currentHtmlNode = (event.target as HTMLElement);
    while (currentHtmlNode != null && !currentHtmlNode.className.includes('section-card')) {
      if (currentHtmlNode.parentElement) {
        currentHtmlNode = currentHtmlNode.parentElement;
      }
    }
    console.log(currentHtmlNode);
    for (let i = 0; i < currentHtmlNode.children.length; i++) {
      if (currentHtmlNode.children[i].tagName === 'MAT-EXPANSION-PANEL-HEADER') {
        currentHtmlNode = (currentHtmlNode.children[i] as HTMLElement);
      }
    }
    console.log(currentHtmlNode);
    for (let i = 0; i < currentHtmlNode.children.length; i++) {
      if (currentHtmlNode.children[i].tagName === 'SPAN') {
        currentHtmlNode = (currentHtmlNode.children[i] as HTMLElement);
      }
    }
    console.log(currentHtmlNode);
    const el = document.createElement('div');
    el.className = 'loader padlock_spinner';
    el.innerText = 'loading..';
    currentHtmlNode.appendChild(el);

    let alert = null;
    // Only set a deadline if all questions have been answered
    if (submission.questions_answered === submission.questions_total) {
      await this.restProvider.getSubmissionDeadlineByID(submission).then((deadline_days) => {
        const sevenDaysDate = new Date();
        const action_item_deadline = deadline_days['action_item_deadline']
        sevenDaysDate.setDate(sevenDaysDate.getDate() + Number(action_item_deadline));
        const deadline = this.formatDate(sevenDaysDate);
        this.restProvider.setAuditDeadlineNotification(submission, deadline, true).then((result) => {
          

          const subIndex = this.data.currentAudit.submissions.findIndex((x) => x.id === submission.id);
          if (subIndex !== -1) {
            // Legacy - this.data.currentAudit[submissionKey].action_items_deadline = deadline;
            this.data.currentAudit.submissions[subIndex].action_items_deadline = deadline;
          }

          alert = {
            status: 'success',
            message: 'Submission deadline set...',
          };
          this.snackBar.open(alert.message, '', {
            duration: 3000,
            verticalPosition: 'top'
          });
        }).then((data) => {
          if (Array.from(currentHtmlNode.children).includes(el)) {
            currentHtmlNode.removeChild(el);
          }
        }).catch((error) => {
          alert = {
            status: 'error',
            message: 'Error setting submission deadline',
          };
          this.snackBar.open(alert.message, 'close', { verticalPosition: 'top' });
          console.log('setDeadlineOnSubmission: ', error)
        });
      }, (error) => {
        alert = {
          status: 'error',
          message: 'Error getting submission deadline',
        };
        this.snackBar.open(alert.message, 'close', { verticalPosition: 'top' });
        console.log('setDeadlineOnSubmission: ', error)
      });

    } else {
      currentHtmlNode.removeChild(el);
      this.dialog.open(UnansweredQuestionsDialogComponent, {
        data: { submission: submission }
      });

    }
  }

  async closeSubmission(currentSubmission: SubmissionReduced, event: MouseEvent) {
    event.stopPropagation();
    console.log(event);
    let currentHtmlNode = (event.target as HTMLElement);
    while (currentHtmlNode != null && !currentHtmlNode.className.includes('section-card')) {
      if (currentHtmlNode.parentElement) {
        currentHtmlNode = currentHtmlNode.parentElement;
      }
    }
    console.log(currentHtmlNode);
    for (let i = 0; i < currentHtmlNode.children.length; i++) {
      if (currentHtmlNode.children[i].tagName === 'MAT-EXPANSION-PANEL-HEADER') {
        currentHtmlNode = (currentHtmlNode.children[i] as HTMLElement);
      }
    }
    console.log(currentHtmlNode);
    for (let i = 0; i < currentHtmlNode.children.length; i++) {
      if (currentHtmlNode.children[i].tagName === 'SPAN') {
        currentHtmlNode = (currentHtmlNode.children[i] as HTMLElement);
      }
    }
    console.log(currentHtmlNode);
    const el = document.createElement('div');
    el.className = 'loader padlock_spinner';
    el.innerText = 'loading..';
    currentHtmlNode.appendChild(el);

    const submission = currentSubmission;
    let alert = null;

    if (submission.questions_answered === submission.questions_total) {
      await this.restProvider.closeSubmissionByID(submission.id).then(data => {
        alert = {
          status: 'success',
          message: 'Submission closed successfully...',
        };
        this.data.moveSubmisionDataAddSectionMetaData(submission, data as Submission);
        console.log(data);
        // currentHtmlNode.removeChild(el);
        // this.data.currentAudit[submissionKey] = data;
      }).catch(err => (alert = {
        status: 'error',
        message: err.error.error || 'Submission has unanswered action items!'
      }));

      if (alert.status === 'success') {
        this.snackBar.open(alert.message, '', {
          duration: 3000,
          verticalPosition: 'bottom'
        });
        console.log('this.data.currentYear', this.data.currentYear);

        this.router.navigate(['/audits', this.data.currentRegionID, this.data.currentYear]);
        this.data.getAuditList(this.data.currentRegionID, this.data.currentYear)

      } else {
        this.snackBar.open(alert.message, 'close', { verticalPosition: 'bottom' });
      }

      if (Array.from(currentHtmlNode.children).includes(el)) {
        currentHtmlNode.removeChild(el);
      }

      // submission.status = 2; // closed status TODO maybe make a data method for closing instead
      // let actionList: string;
      // let d = new Date();
      // actionList = d.toTimeString();
      // actionList += ' ';
      // actionList += d.toDateString();
      // actionList += '\r\n';
      // actionList += '\r\n';
      // actionList += '\r\n';
      // // TODO major problem here it will fail if the list has never been
      // opened!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! actionList += this.data.generateActionList(submission); if
      // (submission.action_list) { actionList += '\r\n'; actionList += '\r\n'; actionList += '\r\n'; actionList += '\r\n'; actionList +=
      // submission.action_list; } submission.action_list = actionList; const auditID = this.data.currentAuditID;
      // Data.submitSubmission(submission); this.restProvider.updateSubmissionByIDForDashBoard(submission).then(data => {
      // this.restProvider.updateRank(auditID.toString()); // TODO do i want to do it here, no i dont the backend should take care of this
      // });
    } else {
      currentHtmlNode.removeChild(el);
      this.dialog.open(UnansweredQuestionsDialogComponent, {
        data: { submission: submission }
      });

    }

  }

  reopenSubmission(currentSubmission: SubmissionReduced, $event: MouseEvent) {
    event.stopPropagation();
    const submission = currentSubmission;
    if (submission.status < 4) {
      submission.status = 1; // opened status this will not reset the score only turn it grey in the dashboard
      const auditID = this.data.currentAuditID;
      const year = new Date(this.data.currentAudit.year);
      this.restProvider.updateSubmissionByIDForDashBoard(submission).then(data => {
        this.restProvider.updateRank(auditID.toString(), year.getFullYear()); // TODO do i want to do it here, no i dont the backend should take care of this
      });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  };

}
