import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType6} from '../../_models';
import {Data} from '../../_providers';
import {BehaviorSubject, Observable} from 'rxjs';


export class Type6DataSource implements DataSource<TableType6> {
  // {
  // reg:'',
  // type:'',
  // model:'',
  // vehicleFile: 'OK\FAIL',
  // liveryCheck: 'OK\FAIL',
  // vehicleCondition: 'OK\FAIL',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType6[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 48) {
      for (let i = this.tableData.table_data.length; i < 48; i++) {
        const tempObj: any = {};
        tempObj.reg = '';
        tempObj.type = '';
        tempObj.model = '';
        tempObj.vehicleFile = '';
        tempObj.liveryCheck = '';
        tempObj.driverCat = '';
        tempObj.vehicleCondition = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  replaceData(data) {

    for (let i = 0; i < data.length; i++) {

      if (data[i].vehicleFile != null) {
        data[i].vehicleFile = data[i].vehicleFile.toUpperCase();
      }
      if (data[i].liveryCheck != null) {
        data[i].liveryCheck = data[i].liveryCheck.toUpperCase();

      }
      if (data[i].driverCat != null) {
        data[i].driverCat = data[i].driverCat.toUpperCase();

      }
      if (data[i].vehicleCondition != null) {
        data[i].vehicleCondition = data[i].vehicleCondition.toUpperCase();

      }
    }
    this.tableData.table_data = data;
    this.tableData.table_data = data;

    this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  dataLength() {
    return this.tableData.table_data.length; //TODO maybe needs to change
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType6[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType6[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.reg = this.tableData.table_data[i].reg;
      tempObj.type = this.tableData.table_data[i].type;
      tempObj.model = this.tableData.table_data[i].model;
      // todo this feels a little hacky maybe change all this to upper but at least it makes it harder for the data to be wrong
      if (this.tableData.table_data[i].vehicleFile != null) {
        tempObj.vehicleFile = this.tableData.table_data[i].vehicleFile.toUpperCase();
      } else {
        tempObj.vehicleFile = '';
      }
      if (this.tableData.table_data[i].driverCat != null) {
        tempObj.driverCat = this.tableData.table_data[i].driverCat.toUpperCase();
      } else {
        tempObj.driverCat = '';
      }
      if (this.tableData.table_data[i].liveryCheck != null) {
        tempObj.liveryCheck = this.tableData.table_data[i].liveryCheck.toUpperCase();
      } else {
        tempObj.liveryCheck = '';
      }
      if (this.tableData.table_data[i].vehicleCondition != null) {
        tempObj.vehicleCondition = this.tableData.table_data[i].vehicleCondition.toUpperCase();
      } else {
        tempObj.vehicleCondition = '';
      }
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateReg(index: number, reg: string) {
    this.tableData.table_data[index].reg = reg;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateType(index: number, type: string) {
    this.tableData.table_data[index].type = type;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateModel(index: number, model: string) {
    this.tableData.table_data[index].model = model;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateVehicleFile(index: number, vehicleFile: string) {
    this.tableData.table_data[index].vehicleFile = vehicleFile;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDriverCat(index: number, vehicleFile: string) {
    this.tableData.table_data[index].driverCat = vehicleFile;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateLiveryCheck(index: number, liveryCheck: string) {
    this.tableData.table_data[index].liveryCheck = liveryCheck;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateVehicleCondition(index: number, vehicleCondition: string) {
    this.tableData.table_data[index].vehicleCondition = vehicleCondition;
    this.data.pushTableUpdate(this.tableData.id);
  }


  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
  }


}
