import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type10DataSource} from './type-10.datasource';

@Component({
  selector: 'app-type-10',
  templateUrl: './type-10.component.html',
  styleUrls: ['./type-10.component.css', '../table-common.css']
})
export class Type10Component implements OnInit {

  displayedColumns = ['driver', 'defect', 'tacho', 'ecHrs', 'rtd', 'secondJobDiscl', 'cpcTraining', 'comments'];

  dataSource: Type10DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type10DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDefect(index: number, defect: string) {
    this.dataSource.updateDefect(index, defect);
  }

  updateTacho(index: number, tacho: string) {
    this.dataSource.updateTacho(index, tacho);
  }

  updateEcHrs(index: number, ecHrs: string) {
    this.dataSource.updateEcHrs(index, ecHrs);
  }

  updateRtd(index: number, rtd: string) {
    this.dataSource.updateRtd(index, rtd);
  }

  updateSecondJobDiscl(index: number, secondJobDiscl: string) {
    this.dataSource.updateSecondJobDiscl(index, secondJobDiscl);
  }

  updateCpcTraining(index: number, cpcTraining: string) {
    this.dataSource.updateCpcTraining(index, cpcTraining);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }


}
