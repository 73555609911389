import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry, MatSelectChange } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BrandOptions } from '../_models/BrandOptions';
import { DropdownOptions } from '../_models/dropdown-options';
import { Data, GlobalSettings } from '../_providers';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  // For Unsubscribing
  private _destroy$ = new Subject<void>();

  imagePrefix: string = environment.imagePrefix;
  public layoutIcon: 'one-columns-layout';

  @Input() brand: BrandOptions = { name: '', logoUrl: '' };
  @Input() subtitle: string;
  @Input() routeBase = '/';
  @Input() menu: null | DropdownOptions = null;

  userRole: any = 0;
  role: 'Auditor' | 'Operator' | 'Admin' | 'Viewer' | 'Depot Manager' | null = null;
  customer: { name: string, logo: string } | null = null;
  canGoBack = false;
  permissionName: string;
  public $isLoading: Subscription;
  public isLoading: boolean;
  user: { role: number, email: string } | null = null;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
    public data: Data,
    private globalSettings: GlobalSettings,
    private _authenticationService: AuthenticationService,
  ) {


    const iconList = [
      { svgIcon: 'two-columns-layout', url: 'assets/img/two-columns-layout.svg' },
      { svgIcon: 'one-columns-layout', url: 'assets/img/one-columns-layout.svg' },
      { svgIcon: 'file-document', url: 'assets/img/file-document.svg' },
      { svgIcon: 'file-document-two', url: 'assets/img/file-document-two.svg' },
      { svgIcon: 'menu-dots', url: 'assets/img/dots-vertical.svg' },
      { svgIcon: 'sign-arrow-right', url: 'assets/img/sign_arrow_right.svg' },
      { svgIcon: 'keyboard-arrow-down', url: 'assets/img/keyboard_arrow_down.svg' },
      { svgIcon: 'user-contact', url: 'assets/img/user-contact.svg' },
      { svgIcon: 'close-sign', url: 'assets/img/close_sign.svg' },
      { svgIcon: 'done-sign', url: 'assets/img/done_sign.svg' },
      { svgIcon: 'lock-sign', url: 'assets/img/lock_sign.svg' },
    ]

    iconList.forEach(({ svgIcon, url }) => this.iconRegistry.addSvgIcon(
      svgIcon, this.sanitizer.bypassSecurityTrustResourceUrl(environment.imagePrefix + url)
    ));

  }

  ngOnInit() {
    this.layoutIcon = 'one-columns-layout';

    this._authenticationService.userDetail$.pipe(takeUntil(this._destroy$))
      .subscribe((userData) => {
        if (userData) {
          this.user = userData;
          this.customer = userData.customer;

          switch (userData.role) {
            case 1: this.role = 'Auditor'; break;
            case 2: this.role = 'Operator'; break;
            case 3: this.role = 'Admin'; break;
            case 4: this.role = 'Viewer'; break;
            case 5: this.role = 'Depot Manager'; break;
            default: this.role = null; break;
          }

        } else {
          this.customer = null;
          this.role = null;
        }
      })

  }

  public toggleFullScreen() {
    if (this.globalSettings.isFullScreen.value) {
      this.layoutIcon = 'one-columns-layout';
    } else {
      // @ts-ignore
      this.layoutIcon = 'two-columns-layout';
    }
    this.globalSettings.toggleFullscreen();
  }

  logoClick() {
    this.router.navigate(['']);
  }

  optionMenuTrigger(optionClicked: MatSelectChange) {
    switch (optionClicked.value) {
      case 'logout': {

        this.router.navigateByUrl('login');
      }
    }
    optionClicked.source.value = ''; // need to reset the value to empty otherwise it wont trigger a change next time its clicked
  }

  loggedIn() {
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    return currentUser != null;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
