import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type12DataSource} from './type-12.datasource';

@Component({
  selector: 'app-type-12',
  templateUrl: './type-12.component.html',
  styleUrls: ['./type-12.component.css', '../table-common.css']
})
export class Type12Component implements OnInit {

  displayedColumns = ['multipleAccidentDrivers', 'comments'];

  dataSource: Type12DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type12DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateMultipleAccidentDrivers(index: number, multipleAccidentDrivers: string) {
    this.dataSource.updateMultipleAccidentDrivers(index, multipleAccidentDrivers);
  }


  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }

}
