import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';

/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.css']
})
export class TableCardComponent implements OnInit {
  displaySettings = [{name: 'driver', displayName: 'Driver', type: 'input'}, {
    name: 'date',
    displayName: 'Date',
    type: 'date'
  }, {name: 'day', displayName: 'Day', type: 'select', options: ['Ok', 'Fail', 'N/A']}, {
    name: 'ccStart',
    displayName: 'CC Start',
    type: 'input'
  }, {name: 'ddcStart', displayName: 'DDC Start', type: 'input'}, {
    name: 'ccFinish',
    displayName: 'CC Finish',
    type: 'input'
  }, {name: 'ddcFinish', displayName: 'DDC Finish', type: 'input'}, {
    name: 'amVariance',
    displayName: 'am Veriance',
    type: 'input'
  }, {name: 'pmVariance', displayName: 'pm Veriance', type: 'input'}, {
    name: 'totalVariance',
    displayName: 'Total Veriance',
    type: 'input'
  }, {name: 'comments', displayName: 'Comments', type: 'input'}];
  displayedColumns = ['driver', 'date', 'day', 'ccStart', 'ddcStart', 'ccFinish', 'ddcFinish', 'amVariance', 'pmVariance', 'totalVariance', 'comments'];
  dataSource = new MatTableDataSource<Element>(ELEMENT_DATA);

  @Input() inputData: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }


}


export interface Element {
  position: number;
  driver: string;
  date: string;
  day: string;
  ccStart: string;
  ddcStart: string;
  ccFinish: string;
  ddcFinish: string;
  amVeriance: string;
  pmVeriance: string;
  totalVeriance: string;
  comments: string;
}

const ELEMENT_DATA: Element[] = [
  {
    position: 1,
    day: '',
    date: '14/8/2017',
    driver: 'Wayne Emlyn Nightingale',
    ccStart: '7:28:00 am',
    ddcStart: '7:28:00 am',
    ccFinish: '6:57:00 pm',
    ddcFinish: '6:57:00 pm',
    amVeriance: '12:00:00 am',
    pmVeriance: '12:00:00 am',
    totalVeriance: '12:00:00 am',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '15/8/2017',
    driver: 'Wayne Emlyn Nightingale',
    ccStart: '7:33:00 am',
    ddcStart: '7:33:00 am',
    ccFinish: '6:43:00 pm',
    ddcFinish: '6:43:00 pm',
    amVeriance: '12:00:00 am',
    pmVeriance: '12:00:00 am',
    totalVeriance: '12:00:00 am',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '16/8/2017',
    driver: 'Wayne Emlyn Nightingale',
    ccStart: '7:29:00 am',
    ddcStart: '7:29:00 am',
    ccFinish: '6:57:00 pm',
    ddcFinish: '6:57:00 pm',
    amVeriance: '12:00:00 am',
    pmVeriance: '12:00:00 am',
    totalVeriance: '12:00:00 am',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '17/8/2017',
    driver: 'Wayne Emlyn Nightingale',
    ccStart: '11:58:00 am',
    ddcStart: '11:58:00 am',
    ccFinish: '7:19:00 pm',
    ddcFinish: '7:19:00 pm',
    amVeriance: '12:00:00 am',
    pmVeriance: '12:00:00 am',
    totalVeriance: '12:00:00 am',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '18/8/2017',
    driver: 'Wayne Emlyn Nightingale',
    ccStart: '7:28:00 am',
    ddcStart: '7:28:00 am',
    ccFinish: '1:27:00 pm',
    ddcFinish: '1:27:00 pm',
    amVeriance: '12:00:00 am',
    pmVeriance: '12:00:00 am',
    totalVeriance: '12:00:00 am',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '19/8/2017',
    driver: 'Rest Day',
    ccStart: '',
    ddcStart: '',
    ccFinish: '',
    ddcFinish: '',
    amVeriance: '',
    pmVeriance: '',
    totalVeriance: '',
    comments: ''
  },
  {
    position: 1,
    day: '',
    date: '20/8/2017',
    driver: 'Rest Day',
    ccStart: '',
    ddcStart: '',
    ccFinish: '',
    ddcFinish: '',
    amVeriance: '',
    pmVeriance: '',
    totalVeriance: '',
    comments: ''
  }

];


