import {Component, Input, OnInit} from '@angular/core';
import {RawTrackerDataDatasource} from './raw-tracker-data.datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {MatOptionSelectionChange, MatSelectChange} from '@angular/material';

@Component({
  selector: 'app-table-data-driven',
  templateUrl: './table-data-driven.component.html',
  styleUrls: ['./table-data-driven.component.css']
})
export class TableDataDrivenComponent implements OnInit {

  @Input() dataArray;
  @Input() headerSelection: string[];

  headers = [];

  rawTrackerDisplayedColumns: string[] = [];
  rawTrackerUserDisplayedColumnNames: string[] = [];
  rawTrackerDisplayFunctions: Function[] = [];
  renderedData: any;

  rawTrackerdataSource: RawTrackerDataDatasource;

  selection = new SelectionModel<any>(true, []);
  loopableDisplayColumns: string[] = [];

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.renderedData.forEach(row => this.selection.select(row));
  }


  constructor() {
  }

  ngOnInit() {
    for (let i = 0; i < this.headerSelection.length; i++) {
      this.headers.push({name: this.headerSelection[i], selected: false, selectedObject: null, column: null});
    }
    this.rawTrackerdataSource = new RawTrackerDataDatasource();
    console.log('data-driven-table');
    console.log(this.headerSelection);
    console.log(this.dataArray);
    this.rawTrackerdataSource.startLoading();


    // let vehicles = JSON.parse(this.dataArray);
    //   console.log('find vehicles service  in Datasource: ', vehicles);

    let initialArray = Object.keys(this.dataArray[0]); // vehicles.uniqueColumnNames; // note this is passed by reference so data does get
                                                       // removed at the slicing stage,
    // in case you find the console log looks strange. This isn't really a problem because it's not used elsewhere but is just not
    // immediately obvious

    // need to be cleared in case any headers change
    let resultArray = [['select'], [], []];
    this.rawTrackerDisplayFunctions = [];


    // for (let i = 0; i < ViewRawTrackerDataComponent.priorityArray[0].length; i++) {
    //   for (let j = 0; j < initialArray.length; j++) {
    //     if (ViewRawTrackerDataComponent.priorityArray[0][i] === initialArray[j]) {
    //       resultArray[0].push(initialArray.splice(j, 1)[0]); // removed matching item from initial array and added it to the result
    // array
    //       resultArray[1].push(ViewRawTrackerDataComponent.priorityArray[1][i]); // removed matching item from initial array and added it
    // to the // result array if (this.functionArray.length > i) { this.rawTrackerDisplayFunctions.push(this.functionArray[i]); } else {
    // this.rawTrackerDisplayFunctions.push(function (value): string { return value; }); } break; // I only want to break out of the j
    // array not the i array } } }
    for (let j = 0; j < initialArray.length; j++) {

      resultArray[0].push(initialArray[j]); // add the remaining array to display at the end of the table
      resultArray[1].push(initialArray[j]); // we have no custom name for this column so just use the one suplied by django
      resultArray[2].push(initialArray[j]); // add the remaining array to display at the end of the table
      this.rawTrackerDisplayFunctions.push(function (string): string {
        return string;
      });
    }
    this.loopableDisplayColumns = resultArray[2];
    this.rawTrackerDisplayedColumns = resultArray[0];
    this.rawTrackerUserDisplayedColumnNames = resultArray[1];
    this.rawTrackerdataSource.loadTrackerPoints(this.dataArray);
    this.renderedData = this.dataArray;


  }

  // when an item is selected there is either 3 or 2 event
  // 1 edit is called with selected true
  // 2 (dependednt on write value) edit is called with selected = false
  // 3 selectedHeader is called
  selectedHeader(event: MatSelectChange, column) {
    console.log(event);
    if (event.value != null) {
      event.value.selectedObject = event.source;
      event.value.selected = true;
      event.value.column = column;

    }
  }

  edit(event: MatOptionSelectionChange) {
    console.log(event);
    if (event.source.value != null) {
      if (event.source.selected) { // item just selected
        if (event.source.value.selectedObject != null) {
          event.source.value.selectedObject.writeValue('');
        }
        // event.source.value.selectedObject  // dont want to edit this here as this comes from the option tag not the select tag
      } else {  // item diselected
        event.source.value.selected = false;
        event.source.value.selectedObject = null;
        event.source.value.column = null;
      }
    }
  }
}
