import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType8} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type8DataSource implements DataSource<TableType8> {
  // {
  // driver:'',
  // reg: 'OK\FAIL',
  // accident: 'OK\FAIL',
  // circumstance: 5,
  // arf: 5,
  // completedArf: 5,
  // inquiry: 5,
  // completedInquiry: 5,
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType8[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 6) {
      for (let i = this.tableData.table_data.length; i < 6; i++) {
        const tempObj: any = {};
        tempObj.driver = '';
        tempObj.reg = '';
        tempObj.accident = '';
        tempObj.circumstance = '';
        tempObj.arf = '';
        tempObj.completedArf = '';
        tempObj.inquiry = '';
        tempObj.completedInquiry = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType8[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType8[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.reg = this.tableData.table_data[i].reg;
      tempObj.accident = this.tableData.table_data[i].accident;
      tempObj.circumstance = this.tableData.table_data[i].circumstance;
      tempObj.arf = this.tableData.table_data[i].arf;
      tempObj.completedArf = this.tableData.table_data[i].completedArf;
      tempObj.inquiry = this.tableData.table_data[i].inquiry;
      tempObj.completedInquiry = this.tableData.table_data[i].completedInquiry;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateReg(index: number, reg: string) {
    this.tableData.table_data[index].reg = reg;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateAccident(index: number, accident: string) {
    this.tableData.table_data[index].accident = accident;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateCircumstance(index: number, circumstance: string) {
    this.tableData.table_data[index].circumstance = circumstance;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateArf(index: number, arf: string) {
    this.tableData.table_data[index].arf = arf;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateCompletedArf(index: number, completedArf: string) {
    this.tableData.table_data[index].completedArf = completedArf;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateInquiry(index: number, inquiry: string) {
    this.tableData.table_data[index].inquiry = inquiry;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateCompletedInquiry(index: number, completedInquiry: string) {
    this.tableData.table_data[index].completedInquiry = completedInquiry;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }


}
