import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditListComponent } from './audit-list/audit-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { QuestionListGuardGuard } from './guards/question-list-guard.guard';
import { SectionListGuardGuard } from './guards/section-list-guard.guard';
import { SubmissionListGuardGuard } from './guards/submission-list-guard.guard';
import { LoginComponent } from './login/login.component';
import { NewFileUploadComponent } from './new-file-upload/new-file-upload.component';
import { QuestionListComponent } from './question-list/question-list.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SectionListComponent } from './section-list/section-list.component';
import { SubmissionListComponent } from './submission-list/submission-list.component';
import { AuthGuard } from './_guards';

const year = new Date().getFullYear();

const routes: Routes =
  [
    {
      path: 'audits/:regionID/:year',
      component: AuditListComponent,
      data: {
        breadcrumb: 'auditList'
      },
      canActivate: [AuthGuard],
      children:
        [
          {
            path: 'submission',
            component: SubmissionListComponent,
            canActivate: [AuthGuard, SubmissionListGuardGuard],
            data: {
              breadcrumb: 'submissionList'
            },
            children:
              [
                {
                  path: 'sections',
                  component: SectionListComponent,
                  canActivate: [AuthGuard, SectionListGuardGuard],
                  data: {
                    breadcrumb: 'sectionList'
                  },
                  children:
                    [
                      {
                        path: 'questions',
                        component: QuestionListComponent,
                        canActivate: [AuthGuard],
                        data: {
                          breadcrumb: 'questionList'
                        },
                        children:
                          [
                            {
                              path: 'files',
                              component: FileUploadComponent,
                              canActivate: [AuthGuard, QuestionListGuardGuard],
                              data: {
                                breadcrumb: 'Attachments'
                              },
                              children:
                                [
                                  {
                                    path: 'newfiles',
                                    component: NewFileUploadComponent,
                                    canActivate: [AuthGuard],
                                    data: {
                                      breadcrumb: 'New attachments'
                                    },
                                  }
                                ]
                            }
                          ]
                      }
                    ]
                }
              ]
          }
        ]
    },
    {
      path: 'login',
      component: LoginComponent,
      data: {
        breadcrumb: 'Login'
      }
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent
    },
    {
      path: 'reset_password_key',
      component: ResetPasswordComponent
    },
    { path: '**', redirectTo: `/audits/1/${year}` }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
