import {Component, Inject, OnInit} from '@angular/core';
import {UnansweredQuestionsDialogComponent} from '../unanswered-questions-dialog/unanswered-questions-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {Data, RestProvider} from '../../_providers';
import {SpinnerLoadingComponent} from '../../spinner-loading/spinner-loading.component';

@Component({
  selector: 'app-split-file-group',
  templateUrl: './split-file-group.component.html',
  styleUrls: ['./split-file-group.component.css']
})
export class SplitFileGroupComponent implements OnInit {


  preperingUpload = false;
  numFilesToUpload = 0;
  numFilesUploaded = 0;
  groupDescription = '';
  submitAttempted = false;

  constructor(public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogData: any,
              public restProvider: RestProvider,
              public data: Data) {
  }

  ngOnInit() {
    let dialogRef = null;
    this.data.loadingSectionList$.subscribe((loaded) => {
      if (loaded) {
        dialogRef = this.dialog.open(SpinnerLoadingComponent, {
          disableClose: false
        });
      } else {
        dialogRef.close();
      }
    });
  }

  split() {
    this.preperingUpload = true;

    // const body    = {
    //   'description': this.groupDescription,
    //   'card': this.data.currentQuestionID
    // };
    // const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    // this.http.post<any>(environment.apiUrl + '/audit' + '/filegroups/', JSON.stringify(body), {headers})
    //   .subscribe(res =>
    //   {

    this.restProvider.createFileGroup(this.groupDescription, this.data.currentQuestionID)
      .then((result: any) => {
        this.numFilesToUpload = this.dialogData.ids.length;
        this.preperingUpload = false;

        for (let i = 0; i < this.dialogData.ids.length; i++) {


          this.restProvider.updateFilesFileGroup(this.dialogData.ids[i], result.id)
          // tslint:disable-next-line:no-shadowed-variable
            .then((result) => {
              this.numFilesUploaded++;
              if (this.numFilesToUpload === this.numFilesUploaded) {
                this.numFilesUploaded = 0;
                this.numFilesToUpload = 0; // resets the view
                this.onUploadCompleate();
              }
            }, (err) => {
              console.log(err);
            });


        }
      }, (err) => {
        console.log(err);
      });
  }

  onUploadCompleate() {
    this.data.refreshAttachmentsForCard(this.data.currentQuestion);
    this.dialogRef.close();
  }

}
