import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {RestProvider} from '../_providers';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  repeatedPassword: string;
  password: string;
  imagePrefix: string = environment.imagePrefix;
  token: string;
  tellUserToResetPassAgain = false;
  success: any;


  constructor(private rest: RestProvider, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    // this.token = this.route.snapshot.paramMap.get('token');
    // console.log(this.token);
    // console.log('tried');
    const token = window.location.search;
    const tokenSplit = token.split('?token=');
    this.token = tokenSplit[1];
    console.log(this.token);
    // this.route.params.subscribe(params => {
    //   this.token = params['token'];
    //   console.log(params);
    //   console.log(this.token);
    //   console.log('tried');
    // });
  }

  submitPassword() {

    if (this.password === this.repeatedPassword) {
      this.rest.newPassword(this.password, this.token, false).then(data => {
        this.success = true;
        console.log(data);
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2500);
        console.log('Reset password: ', data);
      }, reject => {
        this.tellUserToResetPassAgain = true;
      });
    }

  }
}
