import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType12} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type12DataSource implements DataSource<TableType12> {
  // {
  // driver:'',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType12[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 5) {
      for (let i = this.tableData.table_data.length; i < 5; i++) {
        const tempObj: any = {};
        tempObj.multipleAccidentDrivers = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType12[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType12[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.multipleAccidentDrivers = this.tableData.table_data[i].multipleAccidentDrivers;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateMultipleAccidentDrivers(index: number, multipleAccidentDrivers: string) {
    this.tableData.table_data[index].multipleAccidentDrivers = multipleAccidentDrivers;
    this.data.pushTableUpdate(this.tableData.id);
  }


  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }


}
