import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType10} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type10DataSource implements DataSource<TableType10> {
  // {
  // driver:'',
  // defect: 'OK\FAIL\N/A',
  // tacho: 'OK\FAIL\N/A',
  // ecHrs: 'OK\FAIL\N/A',
  // rtd: 'OK\FAIL\N/A',
  // secondJobDiscl: 'OK\FAIL\N/A',
  // cpcTraining: 'OK\FAIL\N/A',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType10[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 6) {
      for (let i = this.tableData.table_data.length; i < 6; i++) {
        const tempObj: any = {};
        tempObj.driver = '';
        tempObj.defect = '';
        tempObj.tacho = '';
        tempObj.ecHrs = '';
        tempObj.rtd = '';
        tempObj.secondJobDiscl = '';
        tempObj.cpcTraining = '';
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType10[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType10[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.defect = this.tableData.table_data[i].defect;
      tempObj.tacho = this.tableData.table_data[i].tacho;
      tempObj.ecHrs = this.tableData.table_data[i].ecHrs;
      tempObj.rtd = this.tableData.table_data[i].rtd;
      tempObj.secondJobDiscl = this.tableData.table_data[i].secondJobDiscl;
      tempObj.cpcTraining = this.tableData.table_data[i].cpcTraining;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }


  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDefect(index: number, defect: string) {
    this.tableData.table_data[index].defect = defect;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateTacho(index: number, tacho: string) {
    this.tableData.table_data[index].tacho = tacho;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateEcHrs(index: number, ecHrs: string) {
    this.tableData.table_data[index].ecHrs = ecHrs;
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateRtd(index: number, rtd: string) {
    this.tableData.table_data[index].rtd = rtd;
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateSecondJobDiscl(index: number, secondJobDiscl: string) {
    this.tableData.table_data[index].secondJobDiscl = secondJobDiscl;
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateCpcTraining(index: number, cpcTraining: string) {
    this.tableData.table_data[index].cpcTraining = cpcTraining;
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }


}
