import { Component, OnInit } from '@angular/core';
import { Type6DataSource } from './../table/type-6/type-6.datasource';
import { Data } from '../_providers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TableViewerComponent } from './../dialogs/table-viewer/table-viewer.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  constructor(private data: Data, public dialog: MatDialog,
    private dialogRef: MatDialogRef<ModalComponent>) { }

  dataSource: Type6DataSource;

  ngOnInit() { }

  onSelectFile(event) {
    console.log(event);

    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {

        const reader = new FileReader();
        reader.readAsText(event.target.files[i]); // read file as data url

        reader.onload = (FileData) => {
          const data = FileData.target['result'];

          const questionID = this.data.currentQuestionID;
          const _dialogRef = this.dialog.open(TableViewerComponent, {
            data: {
              concatenated: true,
              fileData: data, /*this.fileGroups[index]*/
              tableHeaders: ['title']
            }
          });
          _dialogRef.afterClosed().subscribe(result => {
            console.log('result: ', result);
            this.dialogRef.close(result);
          });
        };
      }
    }
    event.target.value = ''; // resetting value to allow reuse of same file
  }

}
