import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type8DataSource} from './type-8.datasource';

@Component({
  selector: 'app-type-8',
  templateUrl: './type-8.component.html',
  styleUrls: ['./type-8.component.css', '../table-common.css']
})
export class Type8Component implements OnInit {

  displayedColumns = ['driver', 'reg', 'accident', 'circumstance', 'arf', 'completedArf', 'inquiry', 'completedInquiry', 'comments'];

  dataSource: Type8DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type8DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateReg(index: number, reg: string) {
    this.dataSource.updateReg(index, reg);
  }

  updateAccident(index: number, accident: string) {
    this.dataSource.updateAccident(index, accident);
  }

  updateCircumstance(index: number, circumstance: string) {
    this.dataSource.updateCircumstance(index, circumstance);
  }

  updateArf(index: number, arf: string) {
    this.dataSource.updateArf(index, arf);
  }

  updateCompletedArf(index: number, completedArf: string) {
    this.dataSource.updateCompletedArf(index, completedArf);
  }

  updateInquiry(index: number, inquiry: string) {
    this.dataSource.updateInquiry(index, inquiry);
  }

  updateCompletedInquiry(index: number, completedInquiry: string) {
    this.dataSource.updateCompletedInquiry(index, completedInquiry);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }


}
