import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Data, GlobalSettings, RestProvider } from '../_providers';
import { Location } from '@angular/common';
import { QuestionListService } from '../question-list/question-list.service';
import { MatDialog } from '@angular/material';
import { FileGroupEditorComponent } from '../dialogs/file-group-editor/file-group-editor.component';
import { FileGroup } from '../_models';
import { AuthenticationService } from '../_services';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  public displayClass = 'top';
  files: any;
  myUrl: number;
  fileGroups: any;

  userRole = 0;

  private loadedQuestionID: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public data: Data, private _location: Location,
    private questionListService: QuestionListService,
    private globalSettings: GlobalSettings,
    public dialog: MatDialog,
    public restProvider: RestProvider,
    private _authenticationService: AuthenticationService
  ) {
    this.globalSettings.isFullScreen$.subscribe(
      () => {
        this.navigate();
      });

    // should come up with a not so low level method to tell component position
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();
      }
    });
    // this code only runs when it is initialised so we can determine the components position from the url used to load it
    this.myUrl = (router.url.match(/\//g) || []).length;

    this.fileGroups = data.currentQuestion.file_groups;
    this.loadedQuestionID = this.data.currentQuestion.id;
  }


  navigate() {
    if (this.globalSettings.isFullScreen.value) {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'full-top';
      } else {
        this.displayClass = 'gone';
      }
    } else {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'top';
      } else if ((this.router.url.match(/\//g) || []).length === this.myUrl + 1) {
        this.displayClass = 'bottom';
      } else {
        this.displayClass = 'gone';
      }
    }
  }


  ngOnInit() {
    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
  }

  public newGroup() {
    const nav = 'newfiles';
    this.router.navigate([nav], { relativeTo: this.route });
  }

  updateView(event, i) {
    if (typeof event.value !== 'undefined') {
      this.fileGroups[i] = event.value();
      this.data.refreshAttachmentsForCard(this.data.currentQuestion);
    }
  }

  editFileGroup(index) {
    const questionID = this.data.currentQuestionID;
    const dialogRef = this.dialog.open(FileGroupEditorComponent, {
      data: {
        fileGroup: this.data.currentQuestion.file_groups.filter(this.notArchived)[index] /*this.fileGroups[index]*/
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        this.data.updateFileGroup(result[0], index);

        for (let i = 1; i < result.length; i++) {
          if (!this.data.updateFileGroup(result[i])) { // failed to update could be a new group
            if (this.data.currentQuestionID === questionID) { // make sure i am still inside the same question
              this.data.addFileGroup(result[i]);
            }
          }
        }
      }
    });
  }

  notArchived(fileGroup: FileGroup) {
    return !fileGroup.archived;
  }
}
