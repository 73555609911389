import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TableViewerComponent } from '../../dialogs/table-viewer/table-viewer.component';
import { Data } from '../../_providers';
import { Type6DataSource } from './type-6.datasource';

@Component({
  selector: 'app-type-6',
  templateUrl: './type-6.component.html',
  styleUrls: ['./type-6.component.css', '../table-common.css']
})
export class Type6Component implements OnInit, OnDestroy {


  displayedColumns = ['reg', 'type', 'model', 'vehicleFile', 'driverCat', 'liveryCheck', 'vehicleCondition', 'comments'];

  dataSource: Type6DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;
  private editableColumns: string[] = ['reg', 'type', 'model', 'vehicleFile', 'driverCat', 'liveryCheck', 'vehicleCondition', 'comments'];

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new Type6DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateReg(index: number, reg: string) {
    this.dataSource.updateReg(index, reg);
  }

  updateType(index: number, type: string) {
    this.dataSource.updateType(index, type);
    // this.loadData();
  }

  updateModel(index: number, model: string) {
    this.dataSource.updateModel(index, model);
  }

  updateVehicleFile(index: number, vehicleFile: string) {
    this.dataSource.updateVehicleFile(index, vehicleFile);
  }

  updateDriverCat(index: number, vehicleFile: string) {
    this.dataSource.updateDriverCat(index, vehicleFile);
  }

  updateLiveryCheck(index: number, liveryCheck: string) {
    this.dataSource.updateLiveryCheck(index, liveryCheck);
  }

  updateVehicleCondition(index: number, vehicleCondition: string) {
    this.dataSource.updateVehicleCondition(index, vehicleCondition);
  }


  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }


  onSelectFile(event) {
    // console.log(event);

    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {

        const reader = new FileReader();
        reader.readAsText(event.target.files[i]); // read file as data url

        reader.onload = (FileData) => {
          // console.log(FileData);
          const data = FileData.target['result'];
          // console.log(data);
          // console.log(DataFuntions.CSVToArray(data));
          // console.log(DataFuntions.CSVToObejectArray(data));

          const questionID = this.data.currentQuestionID;
          const dialogRef = this.dialog.open(TableViewerComponent, {
            data: {
              fileData: data, /*this.fileGroups[index]*/
              tableHeaders: this.editableColumns
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            // console.log(result);
            if (result != null) {
              this.dataSource.replaceData(result);
            }

          });
        };
      }
    }
    event.target.value = ''; // resetting value to allow reuse of same file
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
