import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SectionListService {

  // Observable string sources
  private sectionID = new Subject<number>();

  // Observable string streams
  sectionAnnounced$ = this.sectionID.asObservable();

  // Service message commands
  announceSection(id: number) {
    this.sectionID.next(id);
  }


}
