import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator } from '@angular/material';
import { Data } from '../../_providers';
import { Type2DataSource } from './type-2.datasource';

import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-type-2',
  templateUrl: './type-2.component.html',
  styleUrls: ['./type-2.component.css', '../table-common.css']
})
export class Type2Component implements OnInit, OnDestroy {

  displayedColumns = ['driver', 'date', 'startWork', 'finishWork', 'break', 'hoursWorked', 'actionTaken', 'comments'];

  dataSource: Type2DataSource;

  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  _destroyed$: Subject<void> = new Subject();

  public constructor(private data: Data, public dialog: MatDialog) { }

  ngOnInit() {
    this.dataSource = new Type2DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDate(index: number, newDate: Date) {
    this.dataSource.updateDate(index, newDate);
  }

  updateStartWork(index: number, time: string) {
    this.dataSource.updateStartWork(index, time);
  }

  updateFinishWork(index: number, time: string) {
    this.dataSource.updateFinishWork(index, time);
  }

  updateActionTaken(index: number, answer: string) {
    this.dataSource.updateActionTaken(index, answer);
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
  }

  dataCount(): number {
    return this.dataSource.dataLength();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}


