import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatOptionSelectionChange, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { RawTrackerDataDatasource } from '../../table/table-data-driven/raw-tracker-data.datasource';
import { DataFuntions } from '../../_providers/data/data-functions';
import { UnansweredQuestionsDialogComponent } from '../unanswered-questions-dialog/unanswered-questions-dialog.component';

@Component({
  selector: 'app-table-viewer',
  templateUrl: './table-viewer.component.html',
  styleUrls: ['./table-viewer.component.css']
})
export class TableViewerComponent implements OnInit {
  dataArray: any;
  headerSelection: string[];

  headers = [];
  headers_included = [];

  rawTrackerDisplayedColumns: string[] = [];
  rawTrackerUserDisplayedColumnNames: string[] = [];
  rawTrackerDisplayFunctions: Function[] = [];
  renderedData: any;

  rawTrackerdataSource: RawTrackerDataDatasource;

  selection = new SelectionModel<any>(true, []);
  loopableDisplayColumns: string[] = [];
  private minWidth: number = 0;


  constructor(public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
  }


  completeTable() {
    const returnTable = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      const row = {};

      if (this.headers.length === 1 && this.dialogData.concatenated) {
        const sortedArr = this.headers_included.sort();
        const values = sortedArr.map(v => this.selection.selected[i][v]);
        row[this.headers[0].name] = values.filter(v => v).join(', ');
      } else {
        for (let j = 0; j < this.headers.length; j++) {
          if (this.headers[j].selected) {
            const value = this.selection.selected[i][this.headers[j].column];
            row[this.headers[j].name] = value;
          } else {
            const value = '';
            row[this.headers[j].name] = value;
          }
        }
      }
      returnTable.push(row);
    }
    this.dialogRef.close(returnTable);
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.renderedData.forEach(row => this.selection.select(row));
  }


  ngOnInit() {
    this.headerSelection = this.dialogData.tableHeaders;
    this.dataArray = DataFuntions.CSVToObejectArray(this.dialogData.fileData);

    for (let i = 0; i < this.headerSelection.length; i++) {
      this.headers.push({ name: this.headerSelection[i], selected: false, selectedObject: null, column: null });
    }

    this.rawTrackerdataSource = new RawTrackerDataDatasource();
    // console.log('data-driven-table');
    // console.log(this.headerSelection);
    // console.log(this.dataArray);
    this.rawTrackerdataSource.startLoading();


    // let vehicles = JSON.parse(this.dataArray);
    //   console.log('find vehicles service  in Datasource: ', vehicles);

    const initialArray = Object.keys(this.dataArray[0]); // vehicles.uniqueColumnNames; // note this is passed by reference so data does get
    // removed at the slicing stage,
    // in case you find the console log looks strange. This isn't really a problem because it's not used elsewhere but is just not
    // immediately obvious

    // need to be cleared in case any headers change
    const resultArray = [['select'], [], []];
    this.rawTrackerDisplayFunctions = [];

    for (let j = 0; j < initialArray.length; j++) {

      resultArray[0].push(initialArray[j]); // add the remaining array to display at the end of the table
      resultArray[1].push(initialArray[j]); // we have no custom name for this column so just use the one suplied by django
      resultArray[2].push(initialArray[j]); // add the remaining array to display at the end of the table
      this.rawTrackerDisplayFunctions.push(function (string): string {
        return string;
      });
    }
    this.loopableDisplayColumns = resultArray[2];

    if (this.headers.length === 1 && this.dialogData.concatenated) {
      this.headers_included = resultArray[2];
    }

    this.rawTrackerDisplayedColumns = resultArray[0];
    this.rawTrackerUserDisplayedColumnNames = resultArray[1];
    this.rawTrackerdataSource.loadTrackerPoints(this.dataArray);
    this.renderedData = this.dataArray;

    // this feels like a very inefficient way to have all but the top one selected but I don't understand SelectionModel enough
    this.masterToggle();
    const select = this.selection.selected;
    this.selection.deselect(select[0]);

    this.minWidth = (this.loopableDisplayColumns.length + 1) * 150;
  }

  // when an item is selected there is either 3 or 2 event
  // 1 edit is called with selected true
  // 2 (dependednt on write value) edit is called with selected = false
  // 3 selectedHeader is called
  selectedHeader(event: MatSelectChange, column) {
    // console.log(event);
    if (event.value != null) {
      event.value.selectedObject = event.source;
      event.value.selected = true;
      event.value.column = column;

    }
  }

  edit(event: MatOptionSelectionChange) {
    // console.log(event);
    if (event.source.value != null) {
      if (event.source.selected) { // item just selected
        if (event.source.value.selectedObject != null) {
          event.source.value.selectedObject.writeValue('');
        }
        // event.source.value.selectedObject  // dont want to edit this here as this comes from the option tag not the select tag
      } else {  // item diselected
        event.source.value.selected = false;
        event.source.value.selectedObject = null;
        event.source.value.column = null;
      }
    }
  }


  changeHeaderValue(event: MatOptionSelectionChange, column) {
    if (event.isUserInput) {

      const index = this.headers_included.indexOf(column);

      if (event.source.value === 'include' && index === -1) {
        this.headers_included.push(column);
      } else if (event.source.value === 'exclude' && index >= 0) {
        this.headers_included.splice(index, 1);
      }

    }
  }
}
