import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

export class RawTrackerDataDatasource implements DataSource<any> {

  private trackerPointSubject = new BehaviorSubject<any[]>([]);

  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();
  public paginatorTotalCount: number;

  constructor() {

  }

  startLoading(){
    this.loadingSubject.next(true);
  }

  loadTrackerPoints(trackerPoint){
    this.loadingSubject.next(false);
    this.trackerPointSubject.next(trackerPoint);
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    console.log('Connecting Vehicles raw data source');
    return this.trackerPointSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.trackerPointSubject.complete();
    this.loadingSubject.complete();
  }

}

