import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSelectChange } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SpinnerLoadingComponent } from '../spinner-loading/spinner-loading.component';
import { Data, GlobalSettings, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';
import { SectionListService } from './section-list.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUserObject } from '../_models';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.css'],
  providers: [SectionListService]
})
export class SectionListComponent implements OnInit, OnDestroy {

  _destroy$: Subject<void> = new Subject();
  @Input() inputData: any;
  myUrl: number;
  public displayClass = 'top';
  actionList: string;
  changedText = false;
  userRole = 0;
  fileName = '';
  userDetail: IUserObject | null = null;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    public data: Data,
    public dialog: MatDialog,
    private sectionListService: SectionListService,
    private globalSettings: GlobalSettings,
    private _restProvider: RestProvider,
    private _authenticationService: AuthenticationService
  ) {

    this.globalSettings.isFullScreen$.pipe(takeUntil(this._destroy$)).subscribe(
      isFullScreen => {
        this.navigate();
      });

    // should come up with a not so low level method to tell component position
    router.events.pipe(takeUntil(this._destroy$)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.navigate();
      }
    });
    // this code only runs when it is initlised so we can determin the components position from the url used to load it
    this.myUrl = (router.url.match(/\//g) || []).length;

  }

  navigate() {

    if (this.globalSettings.isFullScreen.value) {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'full-top';
        // }else if ((this.router.url.match(/\//g) || []).length == this.myUrl+1){
        // this.displayClass= "bottom";
      } else {
        this.displayClass = 'gone';
      }
    } else {
      if ((this.router.url.match(/\//g) || []).length === this.myUrl) {
        this.displayClass = 'top';
      } else if ((this.router.url.match(/\//g) || []).length === this.myUrl + 1) {
        this.displayClass = 'bottom';
      } else {
        this.displayClass = 'gone';
      }
    }
  }


  public onTap(section) {
    // if(!section.action_items_needing_review && this.userDetail && [2].includes(this.userDetail.role)){
    //   return
    // }

    this.data.switchSection(section.id);
    const nav = 'questions';
    this.router.navigate([nav], { relativeTo: this.route });
  }

  updateActionList() {
    this._restProvider.updateSubmisionActionList(this.data.currentSubmission, this.data.currentSubmission.action_list);
  }

  ngOnInit(): void {

    const user = this._authenticationService.userDetail$.value;
    this.userRole = user.role;
    this.userDetail = user;

    let dialogRef = null;
    this.data.loadingSectionList$.pipe(takeUntil(this._destroy$)).subscribe((loaded) => {
      if (loaded) {
        dialogRef = this.dialog.open(SpinnerLoadingComponent, {
          disableClose: true,
          panelClass: 'spinner2',
        });
      } else {
        if (dialogRef) { dialogRef.close(); }
      }
    });

    for (let region of this.data.regionList) {
      if (region.id === this.data.currentRegionID) {
        this.fileName += region.name;
      }
    }

    this.fileName += '_' + this.data.currentAudit.name + '_' + this.data.currentSubmission.name + this.data.currentYear + '_ActionList';
  }

  actionForDownload(event: MatSelectChange) {
    console.log('$event', event);
    if (event.value === 'rtf') {
      this.downloadtxtForRTF();
    } else {
      this.downloadtxtForPDF();
    }
  }

  downloadtxtForRTF() {
    const csvContent = 'data:text/text;charset=utf-8,' + this.data.currentSubmission.action_list_text;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    //<Depot><AuditName><ActionsList>.pdf
    link.setAttribute('download', this.fileName + '.rtf');
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  downloadtxtForPDF() {
    const data = {
      content: this.data.currentSubmission.action_list,
      styles: {
        title: { fontSize: 12, marginLeft: 10, bold: true, },
        section: { fontSize: 11, marginLeft: 20, bold: true, marginTop: 20, },
        question: { fontSize: 10, marginLeft: 30, },
        question_note: { fontSize: 10, marginLeft: 40, },
        action_item: { fontSize: 10, marginLeft: 50, },
        comment: { fontSize: 10, marginLeft: 60, }
      }
    };
    pdfMake.createPdf(data).download(this.fileName + '.pdf');
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
