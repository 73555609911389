import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Data } from '../../_providers';
import { AuthenticationService } from '../../_services';

// import {MatDialogRef} from '@angular/material/typings/dialog';

@Component({
  selector: 'app-unanswered-questions-dialog',
  templateUrl: './unanswered-questions-dialog.component.html',
  styleUrls: ['./unanswered-questions-dialog.component.css']
})
export class UnansweredQuestionsDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UnansweredQuestionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _authenticationService: AuthenticationService,
    public data: Data
  ) {
  }

  ngOnInit() {
    const user = this._authenticationService.userDetail$.value;

    // this.data.submission
    this.data.switchSubmission(this.dialogData.submission, user.role); // todo maybe not the best idea
  }

}
