export const noTimeString = '';
export const uncalculatableTime = '????';

export class TimeInputMethods {
  // static readonly noTimeString = '';
  // static readonly uncalculatableTime = '????';

  // Returns empty for no value errors out for unexpected input
  static timeToISOString(time) {
    let returnTimeString: string;
    // if (typeof time === 'undefined') { // user has emptied the time
    //   returnTimeString = this.noTimeString;
    //   console.log("got here");
    // } else {

    const hourMinSplit = time.split(':');

    if (hourMinSplit.length === 2) {
      const d = new Date();
      d.setTime(0);
      d.setUTCHours(parseInt(hourMinSplit[0], 10), parseInt(hourMinSplit[1], 10));
      returnTimeString = d.toISOString();
    } else if (time === '') { // user has emptied the time
      returnTimeString = noTimeString;
      console.log('called empty string not undefined');
    } else {
      console.error('time format came back wrong couldn\'t split into minute and hour');
      console.error(time);
      console.error(hourMinSplit);
      // throw new Error();
    }
    // }

    return returnTimeString;

  }


  static isoStringToTimeInput(isoDate) {
    let returnString = '';

    if (isoDate === 0) { // due to time format change when moving time input from material i used to use miliseconds
      returnString = noTimeString;
    } else if (isoDate === '') {
      returnString = noTimeString;
    } else {
      const d = new Date(isoDate);


      // slice -2 to always get the last 2 chars so if its 09 i get 09 but if its 012 i get 12, used because time input requires 2 chars
      returnString += ('0' + d.getUTCHours()).slice(-2);
      returnString += ':';
      returnString += ('0' + d.getUTCMinutes()).slice(-2);
    }

    return returnString;

  }

  // when editing use the unit tests
  // TODO all these time methods I'm very unsure about!!!
  static timeDifference(time1, time2) {
    let returnTimeString: string;

    if (time1 === noTimeString || time2 === noTimeString || time1 === uncalculatableTime || time2 === uncalculatableTime) {
      returnTimeString = uncalculatableTime;
    } else {
      const hourMinSplit1 = time1.split(':');
      const hourMinSplit2 = time2.split(':');

      if (hourMinSplit1.length === 2 && hourMinSplit2.length === 2) {
        let hour = parseInt(hourMinSplit1[0], 10) - parseInt(hourMinSplit2[0], 10);
        let min = parseInt(hourMinSplit1[1], 10) - parseInt(hourMinSplit2[1], 10);

        // incase the variables are passed in the wrong way round
        if (hour < 0) {
          hour *= -1;
          min *= -1;
        }

        if (min > 59) {
          min -= 60;
          hour += 1;
        }

        if (min < 0 && hour !== 0) {
          min += 60;
          hour -= 1;
        }

        if (min < 0 && hour === 0) {
          min *= -1;
        }

        returnTimeString = hour.toString(10).padStart(2, '0');
        returnTimeString += ':';
        returnTimeString += min.toString(10).padStart(2, '0');
      } else {
        console.error('time format came back wrong couldn\'t split into minute and hour');
        if (hourMinSplit1.length !== 2) {
          console.error('time1 incorrect');
          console.error(time1);
          console.error(hourMinSplit1);
        }
        if (hourMinSplit2.length !== 2) {
          console.error('time2 incorrect');
          console.error(time2);
          console.error(hourMinSplit2);
        }

        throw new Error();
      }
    }
    // console.log('timeDifference
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@'); console.log('time1: %s,
    // time2: ', time1, time2); console.log('result: %s', returnTimeString); console.log('timeDifference
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
    return returnTimeString;
  }

  static timeSummation(time1, time2) {
    let returnTimeString: string;

    if (time1 === noTimeString || time2 === noTimeString || time1 === uncalculatableTime || time2 === uncalculatableTime) {
      returnTimeString = uncalculatableTime;
    } else {
      const hourMinSplit1 = time1.split(':');
      const hourMinSplit2 = time2.split(':');
      if (hourMinSplit1.length === 2 && hourMinSplit2.length === 2) {
        let hour = parseInt(hourMinSplit1[0], 10) + parseInt(hourMinSplit2[0], 10);
        let min = parseInt(hourMinSplit1[1], 10) + parseInt(hourMinSplit2[1], 10);

        if (min > 59) {
          min -= 60;
          hour += 1;
        }


        returnTimeString = hour.toString(10).padStart(2, '0');
        returnTimeString += ':';
        returnTimeString += min.toString(10).padStart(2, '0');
      } else {
        console.error('time format came back wrong couldn\'t split into minute and hour');
        if (hourMinSplit1.length !== 2) {
          console.error('time1 incorrect');
          console.error(time1);
          console.error(hourMinSplit1);
        }
        if (hourMinSplit2.length !== 2) {
          console.error('time2 incorrect');
          console.error(time2);
          console.error(hourMinSplit2);
        }

        throw new Error();
      }
    }


    return returnTimeString;
  }


  static timeSubtraction(time1, time2) {
    let returnTimeString: string;

    if (time1 === noTimeString || time2 === noTimeString || time1 === uncalculatableTime || time2 === uncalculatableTime) {
      returnTimeString = uncalculatableTime;
    } else {
      const hourMinSplit1 = time1.split(':');
      const hourMinSplit2 = time2.split(':');
      if (hourMinSplit1.length === 2 && hourMinSplit2.length === 2) {
        let hour = parseInt(hourMinSplit1[0], 10) - parseInt(hourMinSplit2[0], 10);
        let min = parseInt(hourMinSplit1[1], 10) - parseInt(hourMinSplit2[1], 10);

        if (min > 59) {
          min -= 60;
          hour += 1;
        }

        if (min < 0 && hour > 0) {
          min += 60;
          hour -= 1;
        }

        let negative = false;
        // min can only be less than 0 if hour was less than 1 or someone somehow subtracted with min greater than 59
        if (min < 0) {
          negative = true;
          min *= -1;
        }
        if (hour < 0) {
          negative = true;
          hour *= -1;
        }

        returnTimeString = '';
        if (negative) {
          returnTimeString = '-';
        }
        returnTimeString += hour.toString(10).padStart(2, '0');
        returnTimeString += ':';
        returnTimeString += min.toString(10).padStart(2, '0');
      } else {
        console.error('time format came back wrong couldn\'t split into minute and hour');
        if (hourMinSplit1.length !== 2) {
          console.error('time1 incorrect');
          console.error(time1);
          console.error(hourMinSplit1);
        }
        if (hourMinSplit2.length !== 2) {
          console.error('time2 incorrect');
          console.error(time2);
          console.error(hourMinSplit2);
        }

        throw new Error();
      }
    }
    // console.log('timeSubtraction
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@'); console.log('time1: %s,
    // time2: ', time1, time2); console.log('result: %s', returnTimeString); console.log('timeSubtraction
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');

    return returnTimeString;
  }
}
