import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {TableType4} from '../../_models';
import {Data} from '../../_providers';
import {Observable, BehaviorSubject} from 'rxjs';


export class Type4DataSource implements DataSource<TableType4> {
  // {
  // onRegister:'YES/NO',
  // driver:'Wayne Emlyn Nightingale',
  // licenceOK:'OK\FAIL',
  // date: '14/8/2017',
  // ddcExpiryCopy: 'OK\FAIL\N/A',
  // dqcExpiryCopy:'OK\FAIL\N/A',
  // comments:''
  // },

  filter = '';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 7;
  private tableData: any;
  private tableDisplayData = new BehaviorSubject<TableType4[]>([]);

  public constructor(private data: Data, private tableID: number) {
    this.tableData = data.getTableById(tableID); /// TODO maybe data validation
    if (Object.keys(this.tableData.table_data).length === 0) {
      this.tableData.table_data = [];
    }
    if (this.tableData.table_data.length < 8) {
      for (let i = this.tableData.table_data.length; i < 8; i++) {
        const tempObj: any = {};
        tempObj.onRegister = '';
        tempObj.driver = '';
        tempObj.licenceOK = '';
        tempObj.date = '';
        tempObj.ddcExpiryCopy = 0;
        tempObj.dqcExpiryCopy = 0;
        tempObj.comments = '';
        this.tableData.table_data.push(tempObj);
      }
    }
  }

  dataLength() {
    return this.tableData.table_data.length;
  }

  connect(collectionViewer: CollectionViewer): Observable<TableType4[]> {
    return this.tableDisplayData.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.tableDisplayData.complete();
  }

  loadData(filter = this.filter, sortDirection = this.sortDirection, pageIndex = this.pageIndex, pageSize = this.pageSize) {
    this.filter = filter;
    this.sortDirection = sortDirection;
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;


    const tempData: TableType4[] = [];
    for (let i = pageIndex * pageSize;
      i < this.tableData.table_data.length && i < (pageIndex + 1) * pageSize;
      i++) {
      const tempObj: any = {};
      tempObj.id = i;
      tempObj.onRegister = this.tableData.table_data[i].onRegister;
      tempObj.driver = this.tableData.table_data[i].driver;
      tempObj.licenceOK = this.tableData.table_data[i].licenceOK;
      tempObj.date = new Date(this.tableData.table_data[i].date);
      tempObj.ddcExpiryCopy = this.tableData.table_data[i].ddcExpiryCopy;
      tempObj.dqcExpiryCopy = this.tableData.table_data[i].dqcExpiryCopy;
      tempObj.comments = this.tableData.table_data[i].comments;

      tempData.push(tempObj);
    }

    this.tableDisplayData.next(tempData);
  }

  updateOnRegister(index: number, onRegister: string) {
    this.tableData.table_data[index].onRegister = onRegister;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDriver(index: number, driver: string) {
    this.tableData.table_data[index].driver = driver;
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateLicenceOk(index: number, licenceOK: string) {
    this.tableData.table_data[index].licenceOK = licenceOK;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDate(index: number, newDate: Date) {
    this.tableData.table_data[index].date = newDate.toISOString();
    // this.loadData();
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDdcExpiryCopy(index: number, ddcExpiryCopy: string) {
    this.tableData.table_data[index].ddcExpiryCopy = ddcExpiryCopy;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateDqcExpiryCopy(index: number, dqcExpiryCopy: string) {
    this.tableData.table_data[index].dqcExpiryCopy = dqcExpiryCopy;
    this.data.pushTableUpdate(this.tableData.id);
  }

  updateComments(index: number, comments: string) {
    this.tableData.table_data[index].comments = comments;
    this.data.pushTableUpdate(this.tableData.id);
    // this.loadData();
  }
}
