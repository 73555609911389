import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MessageDialogComponent } from '../_dialogs/message-dialog/message-dialog.component';
import { IUserObject } from '../_models';
import { ActionItemComment } from '../_models/action-item-comments';
import { Data, RestProvider } from '../_providers';
import { AuthenticationService } from '../_services';
import { reject } from 'q';
@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {

  isStatusUpdated = false;

  commentList: ActionItemComment[] = [];
  commentText = '';

  fileList: any[] = [];
  filesToUpload: File[] = [];

  actionItem = null;
  isLoading = false;

  canReview = false;
  role = '';

  actionItemStatus = {
    0: { color: '#ffffff', icon: null, text: 'INACTIVE', class: 'inactive', textColor: '#000000' },
    1: { color: '#000000', icon: 'clock', text: 'AWAITING RESPONSE', class: 'awaiting', textColor: '#000000' },
    2: { color: '#0000ff', icon: 'clock', text: 'REVIEW', class: 'review', textColor: '#0000e8' },
    3: { color: '#008000', icon: 'check_circle', text: 'APPROVED', class: 'approved', textColor: '#004d00' },
    4: { color: '#ffa500', icon: 'check_circle', text: 'APPROVED WITH ADVISORIES', class: 'approvedwa', textColor: '#cc8400' },
    5: { color: '#ff0000', icon: 'matCancel', text: 'REJECTED', class: 'rejected', textColor: '#cc0000' },
    6: { color: '#5b038a', icon: 'clock', text: 'APPROVED WITH FOLLOW-ON COMMENTS', class: 'approvedwc', textColor: '#410064' }
  }

  user: IUserObject;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<CommentModalComponent>,
    private _authenticationService: AuthenticationService,
    private _restProvider: RestProvider,
    public dialog: MatDialog,
    private _data: Data,
  ) {
    const user = this._authenticationService.userDetail$.value;
    this.user = user;
  }

  ngOnInit() {
    this.actionItem = this.dialogData.actionItem;

    this.getListActionItemComments(this.actionItem.id);
  }

  getUserRole(roleId: number | string): string {
    switch (roleId) {
      case 0: return 'System generated';
      case 1: return 'Auditor';
      case 2: return 'Operator';
      case 3: return 'Admin';
      case 4: return 'Viewer';
      case 5: return 'Depot Manager';
    }
    return (typeof roleId === 'string' ? roleId : '');
  }

  getListActionItemComments(id: number) {
    this._restProvider.getListActionItemComments(id)
      .then((res: any[]) => {
        const user = { ...this.user };
        const comments = res.map((c: any) => {
          console.log(c);

          if (typeof c.user === 'number' && c.user === user.id) {
            this.canReview = true;
            c.user = user;
          }
          if (c.user === null) {
            c.user = { role: 0 }
          }
          c.user['role'] = this.getUserRole(c.user.role);
          return c;
        }).reverse();
        // Add the images to the "files" to the comments items
        this.commentList = this.prepareImages(comments);


        if (user.role && !this.canReview) {
          this.canReview = comments.some(({ user }) => user.email === user.email);
        }
      });
  }

  private prepareImages(comments) {
    let commentsWithFiles = [];

    comments.forEach(async comment => {
      comment.files = [];
      if (comment.comment_file) {
        const fileItem = await this.createCommentFileObject(comment.comment_file);
        comment.files.push(fileItem);
      }

    });
    return comments;

  }

  private createCommentFileObject(fileUrl) {
    // get file extension
    const fileSplit = fileUrl.split('?');
    const extension = fileSplit[0].split('.').pop();
    const fileObj: {
      url: null | string | ArrayBuffer,
      name: string,
      type: string,
      type2: string
    } = {
      url: fileUrl,
      name: '',
      type: 'image/png',
      type2: extension
    };
    return fileObj;
  }

  private urlToData64(url: string): any {
    return new Promise<any>((res, rej) => {
      fetch(url).then(response => response.blob()).then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          res(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }).catch((error) => {
      reject(error);
    });
  }

  openImage(file) {
    console.log('File: ', file)
    window.open(file.url, '_blank').focus();
  }

  addComment() {
    const trimCommentText = this.commentText.trim().split(" ").filter((e) => Boolean(e)).join(" ") || "-";

    const _request = this.filesToUpload.length > 0
      ? this._restProvider.addActionItemCommentFiles(this.actionItem.id, trimCommentText, this.filesToUpload)
      : this._restProvider.addActionItemComment(this.actionItem.id, trimCommentText)

    return _request
      .then((res: any) => {
        const user = { ...this.user };

        res.user = user;
        res.user.role = this.getUserRole(user.role);
        let commentFiles = [];
        if (res.comment_file) {
          const fileObj = this.createCommentFileObject(res.comment_file);
          commentFiles.push(fileObj);
        }
        res.files = commentFiles;

        this.commentList.unshift(res);
        this.commentText = '';
        this.fileList = [];
        this.filesToUpload = [];

        this._data.updateActionItems({
          type: 'comment_create',
          card: this.actionItem.card,
          id: this.actionItem.id,
          data: res,
          role: this.user.role
        });

        if (user.role) { this.canReview = true; }
      });
  }

  updateStatus(status: number) {
    console.log("updateStatus: ", status)
    return this._restProvider.updateActionItemById(
      this.actionItem.card,
      this.actionItem.title,
      this.actionItem.id,
      status
    ).then(res => {
      this.actionItem = res;
      this.isStatusUpdated = true;

      this._data.updateActionItems({
        type: 'action_update',
        card: res.card,
        id: res.id,
        data: res,
        role: this.user.role
      });
    });
  }

  updateStatusToReview(status: number) {
    this.isLoading = true;
    this.updateStatus(status)
      .then(() => this.isLoading = false)
      .catch((err) => {
        console.log('error: ', err);
        this.isLoading = false;
      });
  }

  addCommentThenUpdateStatus(status: number) {
    this.isLoading = true;
    this.addComment()
      .then(() => this.updateStatus(status))
      .then(() => this.isLoading = false)
      .catch((err) => {
        console.log('error: ', err);
        this.isLoading = false;
      });
  }
  addCommentResponse() {
    this.isLoading = true;
    this.addComment()
      .then(() => this.isLoading = false)
      .catch((err) => {
        console.log('error: ', err);
        this.isLoading = false;
      });
  }


  onSelectFile(event: Event) { // called each time file input changes

    const target = (event.target as HTMLInputElement);
    const files = target.files || [];
    const filesToBig: string[] = [];
    this.fileList = [];
    this.filesToUpload = [];

    if (files.length === 0) { return; }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file.size > 10000000 /*10MB limit*/ - 200 /*wiggle room*/) {
        filesToBig.push(file.name);
        continue;
      }

      this.filesToUpload[i] = file;
      const reader = new FileReader();
      const fileObj: {
        url: null | string | ArrayBuffer,
        name: string,
        type: string,
        type2: string
      } = {
        url: null,
        name: file.name,
        type: file.name.split('.').pop(),
        type2: file.name.split('.').pop() // file.type.split("/")[1]
      };


      if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(file.name)) {
        const reader = new FileReader();
        reader.onload = (ev) => {
          fileObj.url = reader.result;
          this.fileList[i] = fileObj;
        }
        reader.readAsDataURL(file);
        continue;
      }
      this.fileList[i] = fileObj;
    }

    if (filesToBig.length > 0) {
      const contentText = filesToBig.join('\r\n');
      this.dialog.open(MessageDialogComponent, {
        data: {
          headerText: 'The following files exceed the 10MB limit per file.',
          contentText: contentText
        }
      });
    }

    target.value = "";
  }

  removeFile(index: number) {
    this.fileList.splice(index, 1);
    this.filesToUpload.splice(index, 1);
  }

  closeModal() {
    console.log('this.commentList: ', this.commentList);
    this.dialogRef.close({
      actionItem: this.actionItem,
      isStatusUpdated: this.isStatusUpdated
    });
  }
}
