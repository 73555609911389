import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { Data } from '../_providers';
import { SignatureCaptureComponent } from '../dialogs/signature-capture/signature-capture.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent implements OnChanges {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  @Input() inputSignature: any;
  @Input() auditor: boolean;
  @Input() enabledSignature: boolean = false;
  @Input() nameOfSigner: string;
  @Output() signature = new EventEmitter<string>();

  // nameOfSigner = '';
  // initialised as true to stop error in "this.signatureCaptured || (this.signaturePad.isEmpty() || this.name)" coming back as signaturePad
  // undefined, this is then set correctly in the ngAfterViewInit() method when signaturePad has been initialised
  signatureCaptured: boolean;
  previouslyInitialised = false;

  // private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
  //   'minWidth': 1,
  //   'maxWidth': 2.5,
  //   'canvasWidth': 500,
  //   'canvasHeight': 300
  // };

  constructor(public data: Data, public dialog: MatDialog) {
  }


  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    // this.signaturePad.set('maxWidth', 2.5);
    // this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    if (this.inputSignature) {
      // this.signaturePad.fromDataURL(this.inputSignature);
      // this.signaturePad.off();
      this.signatureCaptured = true;
    } else {
      this.signatureCaptured = false;
    }
    this.previouslyInitialised = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.previouslyInitialised) {
      if (changes.inputSignature.currentValue) {
        // if(!this.signaturePad.isEmpty()){
        // this.signaturePad.clear();}
        // this.signaturePad.fromDataURL(changes.inputSignature.currentValue);
        // this.signaturePad.off();
        this.signatureCaptured = true;
      } else {
        // this.signaturePad.clear();
        this.signatureCaptured = false;
      }
    }

  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    this.signature.emit(this.signaturePad.toDataURL());
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    // console.log('begin drawing');
  }


  clearSignature() {
    if (!this.signatureCaptured) {
      this.signaturePad.clear();
    }
  }

  submitSig() {
    this.signatureCaptured = true;
    this.signaturePad.off();


    this.data.addSignature(this.signaturePad.toDataURL(), this.nameOfSigner, this.auditor).then((result) => {
    }, (err) => {
      console.log(err);
      this.signatureCaptured = false;
      this.signaturePad.on();
      alert('Failed to save signature please try again');
      // TODO Should display error to user in a better way
    });
  }



  captureSignature() {
    this.dialog.open(SignatureCaptureComponent, {
      data: {
        auditor: this.auditor
      }
    });
  }
}

