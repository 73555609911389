import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material';
import {Data} from '../../_providers';
import {tap} from 'rxjs/operators';
import {Type11DataSource} from './type-11.datasource';

@Component({
  selector: 'app-type-11',
  templateUrl: './type-11.component.html',
  styleUrls: ['./type-11.component.css', '../table-common.css']
})
export class Type11Component implements OnInit {

  displayedColumns = ['driver', 'defect', 'rtd', 'tachograph', 'comments'];

  dataSource: Type11DataSource;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() inputData: any;
  @Input() userRole: 0 | 1 | 2 | 3 | 4 | 5 = 0;

  public constructor(private data: Data) {

  }

  ngOnInit() {
    this.dataSource = new Type11DataSource(this.data, this.inputData.table);
    this.dataSource.loadData();

  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadDataPage())
      )
      .subscribe();
  }

  loadDataPage() {
    this.dataSource.loadData(
      '',
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }


  updateDriver(index: number, driver: string) {
    this.dataSource.updateDriver(index, driver);
  }

  updateDefect(index: number, defect: string) {
    this.dataSource.updateDefect(index, defect);
  }

  updateRTD(index: number, rtd: string) {
    this.dataSource.updateRTD(index, rtd);
  }

  updateTachograph(index: number, tachograph: string) {
    this.dataSource.updateTachograph(index, tachograph);
    // this.loadData();//attempted as a quick fix for the form to show number dont work TODO look into turning the form red, with form
    // control
  }

  updateComments(index: number, comments: string) {
    this.dataSource.updateComments(index, comments);
    // this.loadData();
  }

}
